import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import axios from "axios"
import { axiosAuth } from "../config/axiosInstances"
import { API_XANO_CERTIFIED_BASE_URL } from "../config/api_config"
import { Api_url } from "../common/api_url"

export const fetchSuggestedCourse = createAsyncThunk(
    'fetchCourseSuggestedCourse/postPaymentSlice',
    async ({ certified_user_skill_quiz_id, suggested_bundle_type }) => {
        try {
            const response = await axiosAuth.get(API_XANO_CERTIFIED_BASE_URL + Api_url.SuggestedCourseBundle, {
                params: {
                    certified_user_skill_quiz_id,
                    suggested_bundle_type
                }
            })
            return response.data?.data
        } catch (error) {
            console.log(error.message)
        }
    }
)

export const fetchCourseSyllabus = createAsyncThunk(
    'fetchCourseSyllabus',
    async ({ certified_bundle_course_id, type, subject, goal }) => {
        try {
            const response = await axiosAuth.get(API_XANO_CERTIFIED_BASE_URL + Api_url.CourseSyllabus,
            { 
                params: { certified_bundle_course_id, Type: type, subject, goal }
            })
            return response.data?.data
        } catch (error) {
            console.log(error.message)
        }

    }
)

export const fetchToken = createAsyncThunk(
    'fetchToken/postPaymentSlice',
    async ({ id }) => {
        try {
            const response = await axios.post('https://xgfy-czuw-092q.m2.xano.io/api:GaQJaGJH/Create_saved_mcq_url', {
                id
            })
            return response.data
        } catch (error) {
            console.log(error.message)
        }
    }
)

export const fetchPaidFeaturesDetails = createAsyncThunk(
    'fetchPaidFeaturesDetails/postPaymentSlice',
    async ({ token }) => {
        try {
            const response = await axios.get('https://xgfy-czuw-092q.m2.xano.io/api:GaQJaGJH/fetch_post_payment_state', {
                params: {
                    token
                }
            })
            return response.data
        } catch (error) {
            console.log(error.message)
        }
    }
)

export const updateCourseStatus = createAsyncThunk(
    'updateCourseDetails/postPaymentSlice',
    async ({ type, subject, user_id, current_id }) =>{
        try {
            const response = await axios.post('https://xgfy-czuw-092q.m2.xano.io/api:GaQJaGJH/check_if_pre_paid', {
                type,
                subject,
                user_id,
                current_id
            })
            return response.data
        } catch (error) {
            console.log(error)
        }
    }
)

export const userCertifiedOrPersonalisedBundle = createAsyncThunk(
    'userCertifiedOrPersonalisedBundle',
    async ({ course_list, bundle_type, certified_user_skill_id }) => {
        try {
            const response = await axiosAuth.post(API_XANO_CERTIFIED_BASE_URL + Api_url.CreateBundle, {
                course_list, bundle_type, certified_user_skill_id
            })

            return { bundle: response.data?.data, type: bundle_type }
        } catch (error) {
            console.log(error)
        }
    }
)

export const removeFromBundle = createAsyncThunk(
    'removeFromBundle',
    async ({ certified_bundle_id, certified_bundle_course_id, type }) => {
        const response = await axiosAuth.post(API_XANO_CERTIFIED_BASE_URL + Api_url.RemoveFromBundle, { 
            certified_bundle_id, certified_bundle_course_id
        })
        return { bundle: response.data?.data, type } 
    }
)

export const addToBundle = createAsyncThunk(
    'addToBundle',
    async ({ course_name_list, certified_bundle_id, type }) => {
        try {
            const response = await axiosAuth.post(API_XANO_CERTIFIED_BASE_URL + Api_url.AddToBundle, {
                course_name_list, certified_bundle_id
            })
            if (response.status === 200 && Array.isArray(response.data?.data)){
                
                return { bundle: response.data?.data, type }
            }
        } catch (error) {
            console.log(error.message)
        }
    }
)

export const getDashBoardData = createAsyncThunk(
    'getDashboardData',
    async ({ certified_user_skill_id }) => {
        try {
            const response = await axiosAuth.get(API_XANO_CERTIFIED_BASE_URL + Api_url.GetDashBoardData, {
                params: {
                    certified_user_skill_id
                }
            })
            if (response.status === 200){
                return response.data?.data
            }
        } catch (error) {
            console.log(error.message)
        }
    }
)

export const claimThisCourse = createAsyncThunk(
    'claimThisCourse',
    async ({ certified_bundle_course_id }) => {
        try {
            const response = await axiosAuth.post(API_XANO_CERTIFIED_BASE_URL + Api_url.ClaimCurrentCourse, {
                certified_bundle_course_id
            })
            return response.data?.data
        } catch (error) {
            console.log(error.message)
        }
    }
)

export const getWhatsAppToken = createAsyncThunk(
    'getWhatsAppToken',
    async ({ certified_user_skill_id }) => {
        const maxTries = 3
        let tries = 0
        while (tries < maxTries) {
            try {
                const response = await axiosAuth.get(API_XANO_CERTIFIED_BASE_URL + Api_url.GetWhatsAppToken, {
                    params: {
                        certified_user_skill_id
                    }
                })
                return response.data?.data
            } catch (error) {
                tries+=1
                console.log(error.message)
            }
        }
    }
)

const initialState = {
    courseList: {
        suggested_data: []
    },
    deletedList: [],
    courseDetail: {},
    personaliseCourseList: {
        custom_personalized_bundle: []
    },
    certificateBundleList: {
        custom_certified_bundle: []
    },
    discountBasedCertificateBundle: [],
    discountBasedPersonaliseBundle: {
        custom_discounted_certified_personalized: []
    },
    loading: false,
    waToken: '',
    user_id: 0,
    listModalOpened: false,
    paidAddonDetails: {
        addon_details: [],
        bundle_details: []   
    },
    currentCourseDetail: {},
    permanentList: {
        suggested_data: []
    },
    showUserGoalChangeModal: false
}


const postPaymentSlice = createSlice({
    name: 'postPaymentSlice',
    initialState,
    reducers: {
        removeCourse: (state, action) => {
            const newCourseList = state.courseList?.filter(item => item !== action.payload)
            state.courseList = newCourseList
        },
        addToCourseList: (state, action) => {
            state.courseList = {
                ...state.courseList,
                suggested_bundle: action.payload
            }
        },
        setChangedUserGoalCourseList: (state, action) => {
            state.courseList = action.payload
        },
        addToPersonaliseCourseList: (state, action) => {
            state.personaliseCourseList = action.payload
        },
        addToCertificatesList: (state, action) => {
            state.certificateBundleList = action.payload
        },
        addTodeletedList: (state, action) => {
            state.deletedList = [...state.deletedList, action.payload]
        },
        removeFromDeletedList: (state, action) => {
            state.deletedList = state.deletedList.filter(item => item !== action.payload)
        },
        saveToken: (state, action) => {
            state.waToken = action.payload
        },
        setUserIdInPostPayment: (state, action) => {
            state.user_id = action.payload
        },
        setToken: (state, action) => {
            state.waToken = action.payload
        },
        setListModal: (state, action) => {
            state.listModalOpened = action.payload
        },
        setCurrentCourseDetail: (state, action) => {
            state.currentCourseDetail = action.payload
        },
        setPermanentSuggestedBundle: (state, action) => {
            state.permanentList = action.payload
        },
        addItemsToPermanentList: (state, action) => {
            const newList = state.permanentList.suggested_data?.filter(item => !action.payload?.some(course => course.course_name === item.course_name))
            console.log(action.payload)
            console.log("this is new list inside redux", newList)
            state.permanentList.suggested_data = [...newList, ...action.payload]
        },
        setShowGoalChangeModal: (state, action) => {
            state.showUserGoalChangeModal = action.payload
        },
        resetPrepaymentDetails: (state) => {
            state.courseList = []
            state.deletedList = []
            state.courseDetail = {}
            state.personaliseCourseList = []
            state.certificateBundleList = []
            state.permanentList = []
        },
        resetPersonalBundles: (state) => {
            state.personaliseCourseList = []
            state.certificateBundleList = []
        },
        resetPaymentDetails: (state) => {
            state.paidFeatures = {}
        },
        resetPaidAddonDetails: (state) => {
            state.paidAddonDetails = {
                addon_details: [],
                bundle_details: []   
            }
        },
        resetPostPaymentSlice: () => initialState
    },
    extraReducers: (builder) => {
        builder.addCase(fetchSuggestedCourse.fulfilled, (state, action) => {
            state.courseList = action?.payload
        })
        builder.addCase(fetchCourseSyllabus.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(fetchCourseSyllabus.fulfilled, (state, action) => {
            state.loading = false
            state.courseDetail = action?.payload
        })
        builder.addCase(fetchToken.fulfilled, (state, action) => {
            state.waToken = action?.payload
        })
        builder.addCase(userCertifiedOrPersonalisedBundle.fulfilled, (state, action) => {
            const { bundle, type } = action.payload
            if (type === 'custom_personalized_bundle'){
                state.personaliseCourseList = bundle
            }else if (type === 'custom_certified_bundle'){
                state.certificateBundleList = bundle
            }else if(type === 'custom_discounted_certified_personalized'){
                state.discountBasedPersonaliseBundle = bundle
            }else{
                state.discountBasedCertificateBundle = bundle
            }
        })
        builder.addCase(addToBundle.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(addToBundle.fulfilled, (state, action) => {
            const { bundle, type } = action.payload
            if (type === 'custom_certified_bundle'){
                state.certificateBundleList.custom_certified_bundle = bundle
            }else if (type === 'custom_personalized_bundle'){
                state.personaliseCourseList.custom_personalized_bundle = bundle
            }else if (type === 'custom_discounted_certified_personalized'){
                state.discountBasedPersonaliseBundle.custom_discounted_certified_personalized = bundle
            }else{
                if (state.courseList.suggested_data?.length === 0){
                    state.permanentList = {
                        ...state.permanentList,
                        suggested_data: bundle
                    }
                }
                state.courseList.suggested_data = bundle
                if (state.courseList.suggested_data?.length > state.permanentList?.suggested_data?.length){
                        state.permanentList = {
                            ...state.permanentList,
                            suggested_data: state.courseList.suggested_data
                        }
                }
            }
            state.loading = false
        })
        builder.addCase(removeFromBundle.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(removeFromBundle.fulfilled, (state, action) => {
            const { bundle, type } = action.payload
            if (type === 'custom_certified_bundle'){
                state.certificateBundleList.custom_certified_bundle = bundle
            }else if (type === 'custom_personalized_bundle'){
                state.personaliseCourseList.custom_personalized_bundle = bundle
            }else if (type === 'custom_discounted_certified_personalized'){
                state.discountBasedPersonaliseBundle.custom_discounted_certified_personalized = bundle
            }else{
                state.courseList.suggested_data = bundle
            }
            state.loading = false
        })
        builder.addCase(removeFromBundle.rejected, (state) => {
            state.certificateBundleList.custom_certified_bundle = [...state.certificateBundleList.custom_certified_bundle]
            state.personaliseCourseList.custom_personalized_bundle = [...state.personaliseCourseList.custom_personalized_bundle]
            state.discountBasedPersonaliseBundle.custom_discounted_certified_personalized = [...state.discountBasedPersonaliseBundle.custom_discounted_certified_personalized]
            state.courseList.suggested_data = [...state.courseList.suggested_data]
        })
        builder.addCase(getDashBoardData.fulfilled, (state, action) => {
            state.paidAddonDetails = action.payload
        })
        builder.addCase(getWhatsAppToken.fulfilled, (state, action) => {
            state.waToken = action.payload
        })
    }
})

export const { removeCourse, addToCourseList, resetPostPaymentSlice, addTodeletedList, removeFromDeletedList, addToPersonaliseCourseList, addToCertificatesList, saveToken, setUserIdInPostPayment, setToken, setListModal, resetPrepaymentDetails, resetPaymentDetails, resetPersonalBundles, setCurrentCourseDetail, resetPaidAddonDetails, setPermanentSuggestedBundle, setChangedUserGoalCourseList, addItemsToPermanentList, setShowGoalChangeModal } = postPaymentSlice.actions
export const selectSuggestedCourseList = (state) => state?.postPaymentDetails?.courseList
export const selectSuggestedCourseDetail = (state) => state?.postPaymentDetails?.courseDetail
export const selectLoadingStatus = (state) => state?.postPaymentDetails?.loading
export const selecteDeletedList = (state) => state?.postPaymentDetails?.deletedList
export const selectPersonalizeList = (state) => state?.postPaymentDetails?.personaliseCourseList
export const selectCertificateBundleList = (state) => state?.postPaymentDetails?.certificateBundleList
export const selectWaToken = (state) => state?.postPaymentDetails?.waToken
export const selectListModalStatus = (state) => state?.postPaymentDetails?.listModalOpened
export const selectDiscountBasedCertificateBundle = (state) => state?.postPaymentDetails?.discountBasedCertificateBundle
export const selectDashBoardData = (state) => state?.postPaymentDetails?.paidAddonDetails?.addon_details
export const selectBundlesData = (state) => state?.postPaymentDetails?.paidAddonDetails?.bundle_details
export const selectCurrentClaimingCourse = (state) => state.postPaymentDetails?.currentCourseDetail
export const selectDiscountedPersonalizeBundleList = (state) => state.postPaymentDetails?.discountBasedPersonaliseBundle
export const selectPermanentBundle = (state) => state.postPaymentDetails?.permanentList
export const selectShowGoalChangeModal = (state) => state.postPaymentDetails.showUserGoalChangeModal
export default postPaymentSlice.reducer