import React, { useEffect, useState } from 'react'
import { selectRelatedSkills } from '../../redux/multiSkillSelectionSlice'
import { useDispatch, useSelector } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faSearch, faXmark } from '@fortawesome/free-solid-svg-icons'
import { fetchPopularSkills, selectSearchResults, selectSelectedFromSearchResults, selectSelectedItems, setSelectedItems, setSelectedSearchedItems } from '../../redux/searchSlice'
import useDebounce from '../../hooks/useDebounce'
import { Mixpanel } from '../../config/Mixpanel'
import constants from '../../utilities/constants'
import { useAddonContext } from '../../context/AddonFeatureContext'
import { selectUserDetails } from '../../redux/otherDetailsSlice'
import increase from './Increase.json'
import LottieAnimation from '../../components/LottieAnimation'


const CustomisedSkillSelection = ({ minutes, seconds, createBundle }) => {

    const relatedSkills = useSelector(selectRelatedSkills)
    const searchResults = useSelector(selectSearchResults)
    const userDetails = useSelector(selectUserDetails)
    const selectedSkills = useSelector(selectSelectedItems)
    const selectedSkillsFromSearchResults = useSelector(selectSelectedFromSearchResults)

    const email = userDetails?.email

    const [ customizedSkill, setCustomizedSkill ] = useState('')

    const { debouncedValue } = useDebounce(customizedSkill)
    
    const { handleForward } = useAddonContext()

    const dispatch = useDispatch()

    useEffect(() => {
        Mixpanel.track(constants.Mixpanel.CUSTOM_BUNDLE_PAGE_LOADED)
    }, [])

    useEffect(() => {
        if(debouncedValue){
            dispatch(fetchPopularSkills({ searchValue: debouncedValue }))
        }
    }, [debouncedValue, dispatch])

    const handleSelection = (item, searched) => {
        dispatch(setSelectedItems(item))
        if (searched){
            dispatch(setSelectedSearchedItems(item))
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        handleSelection(customizedSkill, true)
    }

    return (
        <div>
            <div className='takeaway max-h-[200px] overflow-y-scroll flex flex-wrap gap-2'>
                {selectedSkillsFromSearchResults?.map((item, index) => {
                    return (<div key={index} className={`p-2 bg-[#316EAB] text-white border border-shadow rounded-lg text-xs h-fit font-medium flex items-center gap-1.5 cursor-pointer`} onClick={() => handleSelection(item, true)}>
                        <div className='w-[14px] h-[14px] rounded-sm bg-shadow flex items-center justify-center'>
                            <FontAwesomeIcon icon={faCheck} />
                        </div>
                        <p>{item}</p>
                    </div>)
                })}
                {relatedSkills?.map((item, index) => {
                    const selected = selectedSkills?.includes(item)
                    return (<div key={index} className={`p-2 ${selected ? 'bg-[#316EAB] text-white border border-shadow' : 'border border-[#70B1DD] text-[#70B1DD]'} rounded-lg text-xs h-fit font-medium flex items-center gap-1.5 cursor-pointer`} onClick={() => handleSelection(item, false)}>
                        <div className={`w-[14px] h-[14px] rounded-sm ${selected ? 'bg-shadow' : 'border border-[#70B1DD]'} flex items-center justify-center`}>
                            {selected && <FontAwesomeIcon icon={faCheck} />}
                        </div>
                        <p>{item}</p>
                    </div>)
                })}
            </div>
            <form onSubmit={handleSubmit} className='mt-4 flex flex-col relative mb-3'>
                <input 
                    type='text'
                    value={customizedSkill}
                    onChange={(e) => setCustomizedSkill(e.target.value)}
                    className='w-full bg-secondary_background rounded-lg first_name focus-visible:border-shadow pl-8 h-10 flex flex-col justify-center text-sm'
                    placeholder='Seach for any skill you want'
                />
                <div className='absolute top-1/2 transform -translate-y-1/2 left-2'>
                    <FontAwesomeIcon icon={faSearch} className='text-[#858A8F]'/>
                </div>
                {customizedSkill && <div className='absolute top-1/2 transform -translate-y-1/2 right-2' onClick={() => setCustomizedSkill('')}>
                    <FontAwesomeIcon icon={faXmark} className='text-2xl'/>
                </div>}
            </form>
            {customizedSkill && (searchResults?.length > 0 ? <div className='takeaway max-h-[200px] overflow-y-scroll flex gap-2 flex-wrap'>
                {searchResults?.map((item, index) => {
                    const selected = selectedSkills?.includes(item)
                    return (<div key={index} className={`${selected ? 'bg-[#316EAB] border border-shadow text-white' : 'border border-[#70B1DD] text-[#70B1DD]'} p-2 rounded-lg text-xs h-fit flex items-center gap-1.5 cursor-pointer`} onClick={() => handleSelection(item, true)}>
                        <div className={`w-[14px] h-[14px] rounded-sm ${selected ? 'bg-shadow' : 'border border-[#70B1DD]'} flex items-center justify-center`}>
                            {selected && <FontAwesomeIcon icon={faCheck} />}
                        </div>
                        <p>{item}</p>
                    </div>)
                }
                )}
            </div> : <div className='bg-secondary_background p-2 rounded-lg' onClick={() => handleSelection(customizedSkill, true)}>
                {customizedSkill}
            </div>)}
            {selectedSkills?.length > 0 && <div className='border-2 border-shadow rounded-xl text-center py-3 mt-4 bg-secondary_background cursor-pointer' onClick={() => {
                Mixpanel.track(constants.Mixpanel.CUSTOM_BUNDLE_CREATED)
                createBundle()
            }} style={{
                boxShadow: '0px 0px 6px 0px #80C242'
            }}>
                <p className='text-sm font-medium mb-1'>Continue with {selectedSkills?.length + 1} Certificates</p>
                <div className='flex items-center justify-center gap-2'>
                    <div className='w-5 h-auto'>
                        <LottieAnimation
                            animation={increase}
                            loop={true}
                        />
                    </div>
                    <p className='text-xs'>It increases your chance of standing out by <span className='text-shadow font-bold mb-1'>{Math.min(84, (selectedSkills?.length + 1)*16)}%</span></p>
                </div>
            </div>}
            <p className='my-2 text-center text-[#B0C1CD]'>or</p>
            <div className={`grounded-radiants ${selectedSkills?.length > 0 ? 'opacity-50' : ''} cursor-pointer h-[65px] text-center`} onClick={() =>  {
                if (selectedSkills?.length === 0){
                    Mixpanel.track(constants.Mixpanel.NEW_ADDON_FEATURE_CERTIFICATES_CONTINUE_WITH_1_CERTIFICATE, {
                        distinct_id: email
                    })
                    handleForward()
                }
            }}>
                <div className={`inside-div bg-[#2D4F71] flex flex-col items-center text-center justify-center`}>
                    <p className='text-xs-xxs text-[#B0C1CD]'>Continue with only 1 Certificate</p>
                    <p className='text-xxs text-white pt-1'>It increases your chance of standing out by <span className='font-bold'>16%</span></p>
                </div>
            </div>
            <div className='flex items-center justify-between mt-2'>
                <p className='text-xs text-white font-semibold text-center mt-1'>Extra <span className='text-span_color'>5%</span> off per Certificate</p>
                <div className='flex items-center justify-center gap-1'>
                    <p className='text-xxs text-[#B0C1CD]'>Discount ends in</p>
                    <p className='text-danger text-xs w-[65px] md:text-xs md:w-[70px] inline-flex justify-center text-center font-semibold px-1 py-0.5 border-2 border-danger rounded'>{minutes.toString().padStart(2, '0')}M:{seconds.toString().padStart(2, '0')}S</p>
                </div>
            </div>
        </div>
    )
}

export default CustomisedSkillSelection