import { saveAs } from 'file-saver'

export const options = [
    {
        id: 1,
        skill:'Beginner',
        description: 'Almost 0 knowledge'
    }, 
    {
        id:2,
        skill:'Beginner',
        description: 'Basic understanding'
    }, 
    {
        id:3,
        skill:'Intermediate',
        description: 'Have done some work on it'
    }, 
    {
        id:4,
        skill:'Expert',
        description: 'Work regularly with it'
    }
]


export const capitalise = (str = "") => {
    return str.charAt(0).toUpperCase() + str.slice(1);
}

export const testimonials = [
    {
        id: 1,
        name: 'Vivek Jarngal',
        image: 'https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Ellipse_907.png?updatedAt=1685776843845',
        designation: 'Freelance Designer',
        description:`“This is exactly what I was looking for”`

    },
    {
        id: 2,
        name: 'Bala Nath Rai',
        image: 'https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Ellipse_907__1_.png?updatedAt=1685776939014',
        designation: 'Account Executive',
        description:`“Wow, I really didn’t know there was so much I needed to learn!”`

    },
    {
        id: 3,
        name: 'Shruti Das',
        image: 'https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Shruti.png?updatedAt=1685777697916',
        designation: 'Web Developer',
        description:`“Finally, a course that understands me & my goals!”`

    },
    {
        id: 4,
        name: 'Somesh Mishra',
        image: 'https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Ellipse_907__3_.png?updatedAt=1685777109765',
        designation: 'Project Manager',
        description:`“I’ve been looking since months when the perfect course was just minutes away..”`

    },
]

export const questionLoader = [
    {
        id: 1,
        description: 'Analysing the skill map'
    },
    {
        id: 2,
        description: 'Generating questions for you'
    },
    {
        id: 3,
        description: 'Loading questions and optimising quiz settings'
    },
    {
        id: 4,
        description: 'Loading questions and optimising quiz settings'
    },
]

export const images = [
    'https://ik.imagekit.io/238r9emirjj/certifiedOptimisation/Group_8427-modified.webp?updatedAt=1720518119244', 
    'https://ik.imagekit.io/238r9emirjj/certifiedOptimisation/Group_8427__1_-modified.webp?updatedAt=1720518119064', 
    'https://ik.imagekit.io/238r9emirjj/certifiedOptimisation/Group_8427__2_-modified.webp?updatedAt=1720518119012', 
    'https://ik.imagekit.io/238r9emirjj/certifiedOptimisation/Group_8427__3_-modified.webp?updatedAt=1720518119173', 
    'https://ik.imagekit.io/238r9emirjj/certifiedOptimisation/Group_8427__4_-modified.webp?updatedAt=1720518119178'
]

export const questionsTag = [
    '86% people who completed this quiz got hired',
    '93% users loved their feedback & course',
    '93% users loved their feedback & course',
    '88% quiz takers felt more confident on completing',
    '70% Users Got Hired Who Completed This Quiz'
]

export const expertsData = [
    {
        expert_name: 'Ravi Garikipati',
        designation: 'Ex-CTO',
        imgLink: 'https://ik.imagekit.io/238r9emirjj/About-us/Rectangle_52.png?updatedAt=1685423721302',
        companyImage: 'https://ik.imagekit.io/238r9emirjj/About-us/Flipkart_-_png_0.png?updatedAt=1685423777121',
        companyName: 'Flipkart'
    },
    {
        expert_name: 'Kaushik Subramanian',
        designation: 'Business Lead',
        imgLink: 'https://ik.imagekit.io/238r9emirjj/About-us/Rectangle_520323456.png?updatedAt=1685423959561',
        companyImage: 'https://ik.imagekit.io/238r9emirjj/About-us/Facebook_-_png_0.png?updatedAt=1685424009735',
        companyName: 'Facebook'
    }
]

export const faqs = [
    {
        id: 0,
        question: 'How is this course personalised for me?',
        answer: 'Our domain experts (with 30+ years of combined experience) along with our AI engine analyse your skills, goals & answers to identify what you need to learn. We then build a detailed syllabus accordingly for you. And the personalisation doesn’t end here. Once you start learning, your course will be further personalised to you as per your needs.'

    },
    {
        id: 1,
        question: 'What all courses do you offer?',
        answer: 'Our personalisation technology works across domains, from career skills like Java to even hobbies like baking. Most users use us to learn work related skills in the domain of Programming, Marketing, Data Science, Design, Business & lots of different tools.'

    },
    {
        id: 2,
        question: 'Does the course really help in jobs?',
        answer: 'Yess! If you learn properly, do your assessments & projects and then showcase your skills in your CV & portfolio to employers it will make a huge difference in your job applications & even your own confidence. Our learners work at top companies from Amazon to TCS and many have gone on to become freelancers & entrepreneurs.'

    },
    {
        id: 3,
        question: 'Does LearnTube make the content too?',
        answer: 'We make personalised, certified courses. We do not make the videos in the course. Those are sourced from across the internet to make sure we get the very best content for you. Most videos are from top YouTube creators. We do the hard work of figuring out what you need to learn, finding the very best videos for it, arranging it in the best order, assessing your skills, solving your doubts, giving you projects to do & then certifying you.'

    },
    {
        id: 4,
        question: 'How do I get certified?',
        answer: 'Once you complete the course & assessments you will get certified immediately. Our certificate is recognised by hundreds of companies.'

    },
    {
        id: 5,
        question: 'Is there any refund policy?',
        answer: 'There sure is. While completing your course, if you find a cheaper, more personalised course than ours you will get your money back! Just reach out to us within 30 days of purchase with the details on hello@careerninja.in'

    },
    {
        id: 6,
        question: 'What all will be unlimited?',
        answer: 'You will be able to create unlimited personalised courses on any topic, get certified upon completing each of them, get unlimited doubt solving support & feedback in multiple languages. Note: To prevent any abuse of the system, at a time you can learn from 3 courses & the moment you complete one of them you can start another one. There is no limit to how many courses you can complete every month.'
    }
]

export const certifiedFaq = [
    {
        id: 0,
        question: 'How do I download my certificate?',
        answer: (<p>certificate will be shared on your Whatsapp number and your email.
        Please be on the lookout for the same through <a href='mailto:noreply@learntube.ai' className='text-secondary-text underline'>noreply@learntube.ai</a> within 1hr of your payment.</p>)
    },
    {
        id: 1,
        question: 'I have purchased multiple certificates how do I get access to them?',
        answer: <p>Your additional certificates are sent to you via Whatsapp and email similar to the first one.</p>
    },
    {
        id: 2,
        question: 'I have paid for a personalised course, how do i get access to it?',
        answer: <p>You will receive a link which will re-direct you to the personalised course you have purchased. The link will be sent on Whatsapp.</p>
    },
    {
        id: 3,
        question: "I'm facing an error during certificate download/course access or unable to access the projects?",
        answer: <p>In such a case we have a dedicated helpline to enable seamless learning for you.Please reach out to our helpline <a className='text-secondary-text underline' href={`https://wa.me/${+918010700729}`}>8735089992</a> or email us <a href='mailto:noreply@learntube.ai' className='text-secondary-text underline'>hello@careerninja.in</a>, our support team will resolve you query.</p>
    },
    {
        id: 4,
        question: 'What is the use of this certificate ? Can I upload it on LinkedIn?',
        answer: <span>The quiz certificate is a testament to your proficiency in the subject you've attempted the quiz for. This is recognized by 1100 + hiring partners and gives you a definite edge over other learners looking for a job. Each certificate comes with a unique code which can be used to verify the legitimacy of the certificate and yes it can be uploaded on LindekIn.</span>
    },
    {
        id: 5,
        question: 'What is the refund policy?',
        answer: <span>We have a no questions asked refund policy incase we are unable to provide you for the service/product you have paid for.</span>
    },
    {
        id: 6,
        question: 'Does LearnTube provide placement services?',
        answer: <span>Yes, we do provide end-to-end placement services under our "ULTRA" program. To know more please contact our helpline 8735089992 or email us <a href='mailto:noreply@learntube.ai' className='text-secondary-text underline'>hello@careerninja.in</a>.</span>
    },
]

export const companyList1 = [
    'https://ik.imagekit.io/238r9emirjj/About-us/Stripe_-_png_0.png?updatedAt=1689841721147',
    'https://ik.imagekit.io/238r9emirjj/About-us/Netflix_-_png_0.png?updatedAt=1689841784275',
    'https://ik.imagekit.io/238r9emirjj/About-us/Twitter__-_png_0.png?updatedAt=1689841630758',
    'https://ik.imagekit.io/238r9emirjj/About-us/Spotify_-_png_0.png?updatedAt=1689841851007',
    'https://ik.imagekit.io/238r9emirjj/About-us/Expedia_-_png_0.png?updatedAt=1689842162702',
    'https://ik.imagekit.io/238r9emirjj/About-us/Apple_-_png_0.png?updatedAt=1689842084926',
    'https://ik.imagekit.io/238r9emirjj/About-us/Adobe_-_png_0.png?updatedAt=1689841946750',
    'https://ik.imagekit.io/238r9emirjj/About-us/Tata_Consultancy_Services_-_png_0.png?updatedAt=1689842026923',
    'https://ik.imagekit.io/238r9emirjj/About-us/Airbnb_-_png_0.png?updatedAt=1689842238525',
    'https://ik.imagekit.io/238r9emirjj/About-us/Slack_-_png_0.png?updatedAt=1689842318844',
    'https://ik.imagekit.io/238r9emirjj/About-us/Intel_-_png_0.png?updatedAt=1689842367676',
    'https://ik.imagekit.io/238r9emirjj/About-us/Figma_-_png_0.png?updatedAt=1689842408135'
]

export const companyList2 = [
    'https://ik.imagekit.io/238r9emirjj/Learntube/Expedia_-_png_0.png?updatedAt=1688820725181',
    'https://ik.imagekit.io/238r9emirjj/Learntube/Apple_-_png_0.png?updatedAt=1688820724720',
    'https://ik.imagekit.io/238r9emirjj/Learntube/Adobe_-_png_0.png?updatedAt=1688820725291',
    'https://ik.imagekit.io/238r9emirjj/Learntube/Tata_Consultancy_Services_-_png_0.png?updatedAt=1688820729741',
]

export const companyList3 = [
    'https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Airbnb_-_png_0.png?updatedAt=1685600253243',
    'https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Slack_-_png_0__1_.png?updatedAt=1685600253185',
    'https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Intel_-_png_0.png?updatedAt=1685600253088',
    'https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Figma_-_png_0.png?updatedAt=1685600252737'
]

export const successStories = [
    {
        id: 1,
        name: 'Kishore V',
        description: "Having completed my MBA in Business Administration, my inclination towards the financial sector led me to a position in a cooperative bank. Seeking a transition to the tech field, a mutual friend recommended LearnTube as the ideal platform for upskilling and acquiring tech skills.\nThe courses, meticulously structured and easily comprehensible, facilitated my learning journey. Within a span of 3 weeks, I successfully completed around 7-8 micro-skills in UI/UX Design. The depth of the course videos and the inclusion of quizzes significantly enhanced my understanding and retention of the material.\nLearnTube's experienced mentors, who are domain experts, conducted weekly sessions, providing me with the opportunity to address any doubts or queries I encountered. I wholeheartedly endorse LearnTube to anyone seeking to upskill and navigate a career transition!",
        videoUrl: 'https://youtu.be/vVUpuvHWaz8',
        from: 'From Finance',
        to: 'Tech in 3 Weeks',
        thumbNail: 'https://ik.imagekit.io/238r9emirjj/certifiedOptimisation/Rectangle%205146.webp?updatedAt=1720517135735',
        thumbNail2: 'https://ik.imagekit.io/238r9emirjj/Post%20Payment/Rectangle%205146%20(9).png?updatedAt=1706100044811'
    },
    {
        id: 2,
        name: 'Fahmida',
        description: "My substantial academic development, spanning from the initial year to the ultimate year, owes a considerable portion of its success to the remarkable support I received in upskilling through a platform I stumbled upon on Instagram, known as LearnTube. Delving into their courses, I made the strategic decision to specialize in digital marketing.\nThe LearnTube experience went beyond merely imparting new skills and securing verified certifications; it became a transformative force in bolstering my resume and offering invaluable interview preparation assistance. The comprehensive nature of the courses allowed me to navigate the intricacies of digital marketing with confidence and competence.",
        videoUrl: 'https://youtu.be/JfGX8-Z55EE',
        from: 'Fresher',
        to: 'A Digital Marketind Specialist',
        thumbNail: 'https://ik.imagekit.io/238r9emirjj/certifiedOptimisation/Rectangle%205146%20(1).webp?updatedAt=1720517135700',
        thumbNail2: 'https://ik.imagekit.io/238r9emirjj/Post%20Payment/Rectangle%205147.png?updatedAt=1706100149035'
    },
    {
        id: 3,
        name: 'SHUBHAM GAIKWAD',
        description: "As a Computer Engineering student, my aspiration was to explore the realm of Web Development. While I possessed some preliminary knowledge, delving into the courses offered by LearnTube unveiled the vastness of the field. Through LearnTube, I not only obtained certifications in HTML, Java, and CSS but also applied these acquired skills in a practical project. The experienced mentors at LearnTube played a pivotal role in providing guidance, imparting real-world knowledge that transcended the theoretical realm.\nThe exceptional team at LearnTube was consistently supportive, readily available whenever I required assistance. This collaborative environment enhanced my learning experience, fostering a sense of confidence and competence in the intricate domain of Web Development. LearnTube not only equipped me with certifications but also facilitated the practical application of skills, bridging the gap between theoretical understanding and real-world implementation.",
        videoUrl: 'https://youtu.be/zQbxAQESw8Y',
        from: 'Computer Engineer',
        to: 'An expert Web Developer',
        thumbNail: 'https://ik.imagekit.io/238r9emirjj/certifiedOptimisation/Rectangle%205146%20(2).webp?updatedAt=1720517135761',
        thumbNail2: 'https://ik.imagekit.io/238r9emirjj/Post%20Payment/Rectangle%205148.png?updatedAt=1706100206471'
    },
    {
        id: 4,
        name: 'Aftab ahmed',
        description: "Despite having a masters in aerospace engineering I was always intrigued by the world of artificial intelligence as well as robotics. That's where LearnTube came in.\n I completed various courses on LearnTube related to AI such as data structures & algorithms, python, machine learning and deep learning. I love the fact that they have content from a whole range of tutors, and you can learn from the ones who best suit you.\nSomething I found very useful was the mentorship program, which ensured that I got valuable advice that sped up my preparation. LearnTube also helped me build valuable projects using cutting-edge technology and make me confident to face interviews, and I'm hopeful that I will get into my dream company.",
        videoUrl: 'https://youtu.be/nPFIEpK8NKE',
        from: 'Pivot from Aersopace engineering',
        to: 'AI',
        thumbNail: 'https://ik.imagekit.io/238r9emirjj/certifiedOptimisation/Rectangle%205146%20(3).webp?updatedAt=1720517135641',
        thumbNail2: 'https://ik.imagekit.io/238r9emirjj/Post%20Payment/Rectangle%205149.png?updatedAt=1706100257026'
    },
    {
        id: 5,
        name: 'Dinesh',
        description: "After my graduation, I was on the lookout for internships and full-time jobs when a friend told me about an app called LearnTube. I signed up and started learning various courses such as Computer Fundamentals and some programming languages. The courses were well structured and the videos explained the concepts in depth, which benefited me a lot in enhancing my skills.\nArmed with stronger and better skillsets, I landed an internship at Candere by Kalyan Jewellers with a great stipend. I kept gaining more skills on LearnTube along with my internship. Once my internship was over, I decided to turn my focus towards getting a full time job.\nI started learning some core skills such as Machine Learning and enhancing my CV and profile, all of which led me to crack an excellent job opportunity at Mirae Asset Capital Markets as a Software Developer, with a significant salary bump and great perks!",
        videoUrl: 'https://youtube.com/shorts/WU2okGs-fJE?feature=share',
        from: 'Graduate',
        to: 'Landing an internship at Kalyan Jewellers',
        thumbNail: 'https://ik.imagekit.io/238r9emirjj/certifiedOptimisation/Rectangle%205146%20(4).webp?updatedAt=1720517135705',
        thumbNail2: 'https://ik.imagekit.io/238r9emirjj/Post%20Payment/Rectangle%205150.png?updatedAt=1706100314081'
    },
    {
        id: 6,
        name: 'Darshan Deshmukh',
        description: "Enrolling in LearnTube initiated my exploration of various Python courses, with a focus on Modules and Packages. Completing these courses significantly enhanced my understanding of Python and its crucial role in data analytics, vital for my ongoing pursuits in this field.\nAddressing challenges or unclear concepts during the learning process became effortless by consulting the experienced mentors on LearnTube. Their guidance played a key role in fortifying my understanding and strengthening foundational concepts.\nAn exceptional feature of these courses is their cost-free nature, ensuring access to valuable learning resources without financial burden. This not only fosters a conducive learning environment but also democratizes education, providing high-quality Python courses to learners without monetary constraints. LearnTube has truly become an invaluable asset in my journey to expertise in Python and data analytics.",
        videoUrl: 'https://youtube.com/shorts/iJxXNE5DsVM?feature=share',
        from: 'Novice',
        to: 'An Expert Data Analyst',
        thumbNail: 'https://ik.imagekit.io/238r9emirjj/certifiedOptimisation/Rectangle%205146%20(5).webp?updatedAt=1720517135623',
        thumbNail2: 'https://ik.imagekit.io/238r9emirjj/Post%20Payment/Rectangle%205151.png?updatedAt=1706100362879'
    },
    {
        id: 7,
        name: 'Ribhu',
        description: "Being a freelancer, I knew that despite having a Masters degree in Mass Communication, I needed to keep updating my skills to have a competitive edge in my career. I started learning Search Engine Marketing on LearnTube to understand the field of SEM in depth and Marketing as a whole and enhance my knowledge.\nThe best part about LearnTube is that they support you through every step of your upskilling journey and help you analyze your progress from start to end and provide insightful tips to boost your learning curve.\nAfter finishing the SEM courses on LearnTube, I have a much deeper understanding about the subject. I am confident in demonstrating my SEM skills to my clients and in the process even gained new clients!",
        videoUrl: 'https://youtu.be/pKkerknQF5k',
        from: 'Passive freelancing',
        to: 'Landing countless clients',
        thumbNail: 'https://ik.imagekit.io/238r9emirjj/certifiedOptimisation/Rectangle%205146%20(6).webp?updatedAt=1720517135714',
        thumbNail2: 'https://ik.imagekit.io/238r9emirjj/Post%20Payment/Rectangle%205152.png?updatedAt=1706100433715'
    },
    {
        id: 9,
        name: 'Sree Varsha',
        description: "Being a computer science and engineering I was always intrigued by the world of Full stack development . That's where LearnTube came in.\nI completed various courses on LearnTube related to Full Stack Development such as data structures & algorithms, Front end development (HTML, CSS, JS and REACT JS) , Backend (MERN) and much more. I love the fact that they have content from a whole range of tutors, and you can learn from the ones who best suit you.\nSomething I found very useful was the mentorship program, which ensured that I got valuable advice that sped up my preparation. LearnTube also helped me build valuable projects using cutting-edge technology and make me confident to face interviews, and I'm hopeful that I will get into my dream company.",
        videoUrl: 'https://youtu.be/3vFEJQarDjg',
        from: 'Computer Science Engineer',
        to: 'A Full Stack Developer',
        thumbNail: 'https://ik.imagekit.io/238r9emirjj/certifiedOptimisation/Rectangle%205146%20(7).webp?updatedAt=1720517135538',
        thumbNail2: 'https://ik.imagekit.io/238r9emirjj/Post%20Payment/Rectangle%205153.png?updatedAt=1706100491936'
    },
    {
        id: 10,
        name: 'Rupa Shree',
        description: "Earning five LearnTube certifications in a month marked the start of my academic journey. The WhatsApp bot amazed me, enabling seamless learning on the go, even amid travel or work. LearnTube's organized courses, with regular quizzes, fortified my understanding and ensured concept retention, boosting confidence in my PGDM studies.\nLearnTube's versatile approach, integrating the WhatsApp bot, made education flexible. The structured curriculum, with interactive quizzes, vitalized my knowledge for PGDM challenges. LearnTube's commitment to enhancing learning was evident, offering a valuable resource that significantly enriched my academic pursuits.",
        videoUrl: 'https://youtu.be/KvygfsHoUUE',
        from: 'Went from achieveing 5 Certificates a month',
        to: 'Aceing her PGDM studies ',
        thumbNail: 'https://ik.imagekit.io/238r9emirjj/certifiedOptimisation/Rectangle%205146%20(8).webp?updatedAt=1720517135770',
        thumbNail2: 'https://ik.imagekit.io/238r9emirjj/Post%20Payment/Rectangle%205154.png?updatedAt=1706100529885'
    },
]

export async function getLinkFromBuffer(testData){
    try{
        const blob = new Blob([testData], {
            type: 'image/jpeg',
        })
        let urlCreator = window.URL || window.webkitURL;
        let imageUrl = urlCreator.createObjectURL( blob )
        return imageUrl;
    }
    catch(err){
        console.log(err);
    }
}

const constants = {

    Mixpanel: {
        // Landing Page 
        LANDING_PAGE: 'Landing-Page loaded',
        LANDING_PAGE_BUTTON_CLICKED: 'Landing-Page Take Quiz Button Was Clicked',

        // Course Selection Page
        COURSE_AND_SKILL_SELECTION_PAGE_LOADED: 'Course and Skill selection page loaded',
        COURSE_PAGE_COURSE_SELECTED: 'Course was selected',
        COURSE_PAGE_SKILL_SELECTED: 'SKill was selected',

        // Skill Selection Page
        SKILL_SELECTION_PAGE_LOADED: 'Skill Selection Page Loaded',
        SKILL_SELECTION_PAGE_SKILL_SELECTED: 'Skill was selected',
        
        // Onboarding Page
        ONBOARDING_PAGE: 'Onboarding-Page loaded',
        ONBOARDING_PAGE_BUTTON_CLICKED: 'Onboarding-Page First Name and Last Name Was Submitted',
        ONBOARDING_PAGE_LOADER_SCREEN_1: 'Onboarding-Page First Screen Was Loaded',
        ONBOARDING_PAGE_LOADER_SCREEN_2: 'Onboarding-Page Second Screen Was Loaded',

        // MCQ Page
        MCQ_PAGE_QUESTION_1: 'MCQ First Question Was Loaded',
        MCQ_PAGE_ANSWER_1: 'MCQ First Question Was Answered',
        MCQ_PAGE_RE_ANSWER_1: 'MCQ First Question Was Answered Again',
        MCQ_PAGE_QUESTION_2: 'MCQ Second Question Was Loaded',
        MCQ_PAGE_ANSWER_2: 'MCQ Second Question Was Answered',
        MCQ_PAGE_RE_ANSWER_2: 'MCQ Second Question Was Answered Again',
        MCQ_PAGE_QUESTION_3: 'MCQ Third Question Was Loaded',
        MCQ_PAGE_ANSWER_3: 'MCQ Third Question Was Answered',
        MCQ_PAGE_RE_ANSWER_3: 'MCQ Third Question Was Answered Again',
        MCQ_PAGE_QUESTION_4: 'MCQ Fourth Question Was Loaded',
        MCQ_PAGE_ANSWER_4: 'MCQ Fourth Question Was Answered',
        MCQ_PAGE_RE_ANSWER_4: 'MCQ Fourth Question Was Answered Again',
        MCQ_PAGE_QUESTION_5: 'MCQ Fifth Question Was Loaded',
        MCQ_PAGE_ANSWER_5: 'MCQ Fifth Question Was Answered',
        MCQ_PAGE_RE_ANSWER_5: 'MCQ Fifth Question Was Answered Again',
        MCQ_PAGE_QUESTION_6: 'MCQ Sixth Question Was Loaded',
        MCQ_PAGE_ANSWER_6: 'MCQ Sixth Question Was Answered',
        MCQ_PAGE_RE_ANSWER_6: 'MCQ Sixth Question Was Answered Again',
        MCQ_PAGE_QUESTION_7: 'MCQ Seventh Question Was Loaded',
        MCQ_PAGE_ANSWER_7: 'MCQ Seventh Question Was Answered',
        MCQ_PAGE_RE_ANSWER_7: 'MCQ Seventh Question Was Answered Again',
        MCQ_PAGE_QUESTION_8: 'MCQ Eigth Question Was Loaded',
        MCQ_PAGE_ANSWER_8: 'MCQ Eigth Question Was Answered',
        MCQ_PAGE_RE_ANSWER_8: 'MCQ Eigth Question Was Answered Again',
        MCQ_PAGE_QUESTION_9: 'MCQ Ninth Question Was Loaded',
        MCQ_PAGE_ANSWER_9: 'MCQ Ninth Question Was Answered',
        MCQ_PAGE_RE_ANSWER_9: 'MCQ Ninth Question Was Answered Again',
        MCQ_PAGE_QUESTION_10: 'MCQ Tenth Question Was Loaded',
        MCQ_PAGE_ANSWER_10: 'MCQ Tenth Question Was Answered',
        MCQ_PAGE_RE_ANSWER_10: 'MCQ Tenth Question Was Answered Again',

        // Register Page
        REGISTER_PAGE: 'Register Page Was Loaded',
        REGISTER_PAGE_PHONE_NO: 'Register Page Phone No Was Submitted',
        REGISTER_PAGE_EMAIL: 'Register Page Email Was Submitted',
        REGISTER_PAGE_LOADER: 'Register Page Loader Screen Loaded',
        REGISTER_PAGE_JOB_ROLE: 'Register Page Job Role Was Submitted',

        // Analysis Page
        ANALYSIS_PAGE: 'Analysis Page Was Loaded',
        SEO_ANALYSIS_PAGE_LOADED: 'SEO Analysis Page Loaded',
        ANALYSIS_PAGE_ADDITIONAL_FEATURE: 'Analysis Page Additional Features Was Clicked',
        ANALYSIS_PAGE_ADDITIONAL_FEATURE_1: 'Analysis Page Additional Feature One Was Selected',
        ANALYSIS_PAGE_ADDITIONAL_FEATURE_1_REMOVED: 'Analysis Page Additional Feature One Was Removed',
        ANALYSIS_PAGE_ADDITIONAL_FEATURE_2: 'Analysis Page Additional Feature Two Was Selected',
        ANALYSIS_PAGE_ADDITIONAL_FEATURE_2_REMOVED: 'Analysis Page Additional Feature Two Was Removed',
        ANALYSIS_PAGE_ADDITIONAL_FEATURE_3: 'Analysis Page Additional Feature Three Was Selected',
        ANALYSIS_PAGE_ADDITIONAL_FEATURE_3_REMOVED: 'Analysis Page Additional Feature Three Was Removed',
        ANALYSIS_PAGE_STICKY_BUTTON: 'Analysis Page Sticky Button Was Clicked',
        ANALYSIS_PAGE_NORMAL_BUTTON_TOP: 'Analysis Page Normal Top Button Was Clicked',
        ANALYSIS_PAGE_NORMAL_BUTTON_DOWN: 'Analysis Page Normal Bottom Button Was Clicked',
        ANALYSIS_PAGE_ANALYSIS_BUTTON: 'Analysis Page Unlock Analysis Button Was Clicked',

        // Saved Analysis Page
        SAVED_ANALYSIS_PAGE: 'Saved Analysis Page Was Loaded',
        SEO_SAVED_ANALYSIS_PAGE_LOADED: 'SEO Analysis Page Loaded',
        SAVED_ANALYSIS_PAGE_ADDITIONAL_FEATURE: 'Saved Analysis Page Additional Features Was Clicked',
        SAVED_ANALYSIS_PAGE_ADDITIONAL_FEATURE_1: 'Saved Analysis Page Additional Feature One Was Selected',
        SAVED_ANALYSIS_PAGE_ADDITIONAL_FEATURE_1_REMOVED: 'Saved Analysis Page Additional Feature One Was Removed',
        SAVED_ANALYSIS_PAGE_ADDITIONAL_FEATURE_2: 'Saved Analysis Page Additional Feature Two Was Selected',
        SAVED_ANALYSIS_PAGE_ADDITIONAL_FEATURE_2_REMOVED: 'Saved Analysis Page Additional Feature Two Was Removed',
        SAVED_ANALYSIS_PAGE_ADDITIONAL_FEATURE_3: 'Saved Analysis Page Additional Feature Two Was Selected',
        SAVED_ANALYSIS_PAGE_ADDITIONAL_FEATURE_3_REMOVED: 'Saved Analysis Page Additional Feature Two Was Removed',
        SAVED_ANALYSIS_PAGE_STICKY_BUTTON: 'Saved Analysis Page Sticky Button Was Clicked',
        SAVED_ANALYSIS_PAGE_NORMAL_BUTTON_TOP: 'Saved Analysis Page Normal Top Button Was Clicked',
        SAVED_ANALYSIS_PAGE_NORMAL_BUTTON_DOWN: 'Saved Analysis Page Normal Bottom Button Was Clicked',
        SAVED_ANALYSIS_PAGE_ANALYSIS_BUTTON: 'Saved Analysis Page Unlock Analysis Button Was Clicked',

        //Leaderboard Page
        LEADERBOARD_PAGE_LOADED : 'Leaderboard Page was Loaded',
        LEADERBOARD_PAGE_GET_MORE_CERTIFICATES : 'Leaderboard Page Get More Certificates was Clicked',
        LEADERBOARD_PAGE_PERFECT_YOUR_SKILLS: 'Leaderboard Page View Personalised Course was Clicked',
        LEADERBOARD_PAGE_GET_IN_THE_TOP : 'Leaderboard Page Top 10 was Clicked',

        // New Leaderboard Page loaded
        NEW_LEADERBOARD_PAGE_LOADED: 'New Leaderboard Page was loaded',
        NEW_LEADERBOARD_PAGE_GET_MORE_CERTIFICATE_CARD_CLICKED: 'New Leaderboard Page Get More Certificates was Clicked',
        NEW_LEADERBOARD_PAGE_PERFECT_YOUR_SKILLS_CARD_CLICKED: 'New Leaderboard Page Get Personalised Course was Clicked',
        NEW_LEADERBOARD_PAGE_LEADERBOARD_BUTTON_CLICKED: 'New Leaderboard Page Leaderboard Button was Clicked',
        NEW_LEADERBOARD_PAGE_ANALYSIS_PERSONALISED_COURSE_BUTTON_CLICKED: 'New Leaderboard Page Analysis Personalised Button was Clicked',
        NEW_LEADERBOARD_PAGE_SOMETHING_ELSE_CLICKED: 'New Leaderboard Page Something Else Button was Clicked',
        NEW_LEADERBOARD_PAGE_STICKY_BUTTON_FOR_PERSONALISE_CLICKED: 'New Leaderboard Page Sticky Button for Personalised Course was Clicked',
        NEW_LEADERBOARD_PAGE_STICKY_BUTTON_FOR_MORE_CERTIFICATES_CLICKED: 'New Leaderboard Page Sticky Button For More Certificates was Clicked',
        NEW_LEADERBOARD_PAGE_CERTIFICATE_WAS_DOWNLOADED: 'New Leaderboard Page Download Button was Clicked',
    
        //MultiSelectionPage
        MULTISELECTION_PAGE_LOADED : 'MultiSelectionSkillPage was Loaded',
        MULTISELECTION_PAGE_COURSE : 'MultiSelectionSkillPage Course was Selected',
        MULTISELECTION_PAGE_SKILL : 'MultiSelectionSkillPage Skill was Selected',
        MULTISELECTION_PAGE_SKILL_UNSELECT: 'MultiSelectionSkillPage Skill was Unselected',
        MULTISELECTION_PAGE_NEXT_BUTTON : 'MultiSelectionSkillPage Skill Next Button was Selected',

        // New MultiSkillSelectionPage
        NEW_MULTISKILL_SELECTION_PAGE: 'New MultiSkillSelectionPage was loaded',
        NEW_MULTISKILL_SELECTION_PAGE_NEXT_BUTTON: 'New MultiSkillSelectionPage Next button was clicked',
        NEW_MULTISKILL_SELECTION_PAGE_SKILL_WAS_SELECTED: 'New MultiSkillSelectionPage Skill was selected',
        NEW_MULTISKILL_SELECTION_PAGE_SKILL_WAS_REMOVED: 'New MultiSkillSelectionPage Skill was removed',

        // Multi Skill Selection Pricing Page
        MULTI_SKILL_SELECTION_PRICING_PAGE: 'Multi skill selection Pricing Page was Selected',
        MULTI_SKILL_SELECTION_PRICING_PAGE_FEATURE_1_SELECTED: 'Multi skill selection Pricing Page Feature 1 was Selected',
        MULTI_SKILL_SELECTION_PRICING_PAGE_FEATURE_2_SELECTED: 'Multi skill selection Pricing Page Feature 2 was Selected',
        MULTI_SKILL_SELECTION_PRICING_PAGE_FEATURE_1_REMOVED: 'Multi skill selection Pricing Page Feature 1 was Removed',
        MULTI_SKILL_SELECTION_PRICING_PAGE_FEATURE_2_REMOVED: 'Multi skill selection Pricing Page Feature 2 was Removed',
        MULTI_SKILL_SELECTION_PRICING_PAGE_STICKY_BUTTON: 'Multi skill selection Page Sticky Button Was Clicked',
        MULTI_SKILL_SELECTION_PRICING_PAGE_NORMAL_BUTTON_TOP: 'Multi skill selection Page Normal Top Button Was Clicked',
        MULTI_SKILL_SELECTION_PRICING_PAGE_NORMAL_BUTTON_DOWN: 'Multi skill selection Page Normal Bottom Button Was Clicked',

        // Certificate Course
        LEADERBOARD_PERSONALISE_BUTTON_CLICKED: "Personalise course button in leaderboard clicked",
        CERTIFICATE_COURSE_PAGE_LOADED: "Personalise course page loaded",
        CERTIFICATE_COURSE_PAGE_STICKY_BUTTON: "Personalise course sticky button was clicked",
        CERTIFICATE_COURSE_PAGE_NORMAL_BUTTON: "Personalise course normal button was clicked",

        // Skill Level Selection Page
        SKILL_LEVEL_PAGE_LOADED: "Skill Level page loaded",
        SKILL_LEVEL_PAGE_SKILL_SELECTED: "Skill Level page skill was selected",
        SKILL_LEVEL_SELECTION_PAGE_LOADER_SCREEN_1: "Skill Level Selection Page Loader 1 Loaded",
        SKILL_LEVEL_SELECTION_PAGE_LOADER_SCREEN_2: "Skill Level Selection Page Loader 2 Loaded",
        SKILL_LEVEL_SELECTION_PAGE_LOADER_SCREEN_3: "Skill Level Selection Page Loader 3 Loaded",
        SKILL_LEVEL_SELECTION_PAGE_LOADER_SCREEN_4: "Skill Level Selection Page Loader 4 Loaded",

        // Questions Page
        QUESTIONS_PAGE: "Questions page was loaded",
        QUESTION_1_ANSWERED: "Question 1 was answered",
        QUESTION_2_ANSWERED: "Question 2 was answered",
        QUESTION_3_ANSWERED: "Question 3 was answered",
        QUESTION_4_ANSWERED: "Question 4 was answered",
        QUESTION_5_ANSWERED: "Question 5 was answered",
        QUESTION_1_NEXT_BUTTON: "Question 1 next button was clicked",
        QUESTION_2_NEXT_BUTTON: "Question 2 next button was clicked",
        QUESTION_3_NEXT_BUTTON: "Question 3 next button was clicked",
        QUESTION_4_NEXT_BUTTON: "Question 4 next button was clicked",
        QUESTION_5_NEXT_BUTTON: "Question 5 next button was clicked",

        // Analysis Page
        ANALYSIS_PAGE_LOADED: "Analysis Page Loaded",
        ANALYSIS_PAGE_LOADER_SCREEN_1: "Analysis Page Loader Screen 1 Loaded",
        ANALYSIS_PAGE_LOADER_SCREEN_2: "Analysis Page Loader Screen 2 Loaded",
        ANALYSIS_PAGE_LOADER_SCREEN_3: "Analysis Page Loader Screen 3 Loaded",
        ANALYSIS_PAGE_LOADER_SCREEN_4: "Analysis Page Loader Screen 4 Loaded",
        ANALYSIS_STICKY_BUTTON: "Analysis Page Sticky Button was Clicked",
        ANALYSIS_NORMAL_BUTTON: "Analysis Normal Button was Clicked",
        SKIP_ADD_ON_BUTTON: "Skip Add On Button was Clicked",

        // Personalise Course
        PERSONALISE_COURSE_PAGE_LOADED: "Personalise course page loaded",
        PERSONALISE_COURSE_PAGE_STICKY_BUTTON: "Personalise course sticky button was clicked",
        PERSONALISE_COURSE_PAGE_NORMAL_BUTTON: "Personalise course normal button was clicked",

        // Personalise course pricing page
        PERSONALISE_COURSE_PRICING_PAGE: "Personalise course pricing page loaded",
        PERSONALISE_COURSE_PRICING_PAGE_FEATURE_1: "Personalise course pricing page feature 1 selected",
        PERSONALISE_COURSE_PRICING_PAGE_FEATURE_2: "Personalise course pricing page feature 2 selected",
        PERSONALISE_COURSE_PRICING_PAGE_FEATURE_1_REMOVED: "Personalise course pricing page feature 1 removed",
        PERSONALISE_COURSE_PRICING_PAGE_FEATURE_2_REMOVED: "Personalise course pricing page feature 2 removed",
        PERSONALISE_COURSE_PRICING_PAGE_STICKY_BUTTON: "Personalise course pricing page sticky button clicked",
        PERSONALISE_COURSE_PRICING_PAGE_NORMAL_BUTTON: "Personalise course pricing page normal button clicked",

        // Certified course pricing page
        CERTIFICATE_COURSE_PRICING_PAGE: "Certified course pricing page loaded",
        CERTIFICATE_COURSE_PRICING_PAGE_FEATURE_1: "Certified course pricing page feature 1 selected",
        CERTIFICATE_COURSE_PRICING_PAGE_FEATURE_2: "Certified course pricing page feature 2 selected",
        CERTIFICATE_COURSE_PRICING_PAGE_FEATURE_1_REMOVED: "Certified course pricing page feature 1 removed",
        CERTIFICATE_COURSE_PRICING_PAGE_FEATURE_2_REMOVED: "Certified course pricing page feature 2 removed",
        CERTIFICATE_COURSE_PRICING_PAGE_STICKY_BUTTON: "Certified course pricing page sticky button clicked",
        CERTIFICATE_COURSE_PRICING_PAGE_NORMAL_BUTTON: "Certified course pricing page normal button clicked",

        // Flightbooking Style Addon features
        NEW_ADDON_FEATURE_CERTIFICATES_SELECTION_PAGE: "New Addon multiple certificate selection page loaded",
        NEW_ADDON_FEATURE_CERTIFICATES_THREE_CERTIFICATES_SELECTED: "New Addon three certificates bundle selected",
        NEW_ADDON_FEATURE_CERTIFICATES_THREE_CERTIFICATES_REMOVED: "New Addon three certificates bundle removed",
        NEW_ADDON_FEATURE_CERTIFICATES_FIVE_CERTIFICATES_SELECTED: "New Addon five certificates bundle selected",
        NEW_ADDON_FEATURE_CERTIFICATES_FIVE_CERTIFICATES_REMOVED: "New Addon five certificates bundle removed",
        NEW_ADDON_FEATURE_CERTIFICATES_SEVEN_CERTIFICATES_SELECTED: "New Addon Seven certificates bundle selected",
        NEW_ADDON_FEATURE_CERTIFICATES_SEVEN_CERTIFICATES_REMOVED: "New Addon Seven certificates bundle removed",
        NEW_ADDON_FEATURE_CERTIFICATES_CONTINUE_WITH_1_CERTIFICATE: "New Addon certificates continue with 1 certificate button clicked",
        NEW_ADDON_FEATURE_HANDLE_BACKWARD_FROM_CERTIFICATES_PAGE: "New Addon going back button from certificates page clicked",
        NEW_ADDON_FEATURE_CERTIFICATES_STICKY_BAR_NEXT_BUTTON: "New Addon certificates sticky bar next button clicked",
        NEW_ADDON_FEATURE_CERTIFICATES_TOP_SKIP_THIS_BUTTON: "New Addon certicates top skip this button clicked",
        NEW_ADDON_FEATURE_CERTIFICATES_TOP_MOVE_FORWARD_THIS_BUTTON: "New Addon certicates top moveforward button clicked",
        NEW_ADDON_FEATURE_PERSONAL_AND_PRO_MEMBERSHIP_PAGE: "New Addon personal and pro memberships selection page loaded",
        NEW_ADDON_FEATURE_PERSONAL_AND_PRO_MEMBERSHIP_PERSONALISED_COURSE_SELECTED: "New Addon personal and pro memberships personalised course selected",
        NEW_ADDON_FEATURE_PERSONAL_AND_PRO_MEMBERSHIP_PERSONALISED_COURSE_REMOVED: "New Addon personal and pro memberships personalised course removed",
        NEW_ADDON_FEATURE_PERSONAL_AND_PRO_MEMBERSHIP_PRO_MEMBERSHIP_COURSE_SELECTED: "New Addon personal and pro memberships pro-membership selected",
        NEW_ADDON_FEATURE_PERSONAL_AND_PRO_MEMBERSHIP_PRO_MEMBERSHIP_COURSE_REMOVED: "New Addon personal and pro memberships pro-membership removed",
        NEW_ADDON_FEATURE_PERSONAL_AND_PRO_MEMBERSHIP_CONTINUE_WITH_BUTTON: "New Addon personal and pro memberships continue with button clicked",
        NEW_ADDON_FEATURE_PERSONAL_AND_PRO_MEMBERSHIP_ANALYSIS_BUTTON: "New Addon personal and pro memberships personalised course selected from Analysis section",
        NEW_ADDON_FEATURE_PERSONAL_AND_PRO_MEMBERSHIP_STICKY_BAR_GET_CERTIFICATE_BUTTON: "New Addon personal and pro memberships sticky bar get certificate button clicked",
        NEW_ADDON_FEATURE_PERSONAL_AND_PRO_MEMBERSHIP_SKIP_THIS_BUTTON: "New Addon personal and pro memberships skip this button clicked",
        NEW_ADDON_FEATURE_PERSONAL_AND_PRO_MEMBERSHIP_BACK_BUTTON: "New Addon personal and pro memberships back button clicked",
        NEW_ADDON_FEATURE_PERSONAL_AND_PRO_MEMBERSHIP_BOTTOM_STICKY_SKIP_BUTTON: "New Addon personal and pro memberships bottom sticky bar skip button clicked",
        NEW_ADDON_FEATURE_PERSONAL_AND_PRO_MEMBERSHIP_BOTTOM_STICKY_ADDING_BOTH_ADDON_BUTTON: "New Addon personal and pro memberships bottom sticky bar adding both addon button clicked",
        NEW_ADDON_FEATURE_PERSONAL_AND_PRO_MEMBERSHIP_FOUR_WEEK_PLACEMENT_SELECTED: "New Addon personal and pro memberships four week placement selected",
        NEW_ADDON_FEATURE_PERSONAL_AND_PRO_MEMBERSHIP_FOUR_WEEK_PLACEMENT_REMOVED: "New Addon personal and pro memberships four week placement removed",
        NEW_ADDON_FEATURE_PERSONAL_AND_PRO_MEMBERSHIP_ONE_ON_ONE_MENTORSHIP_SELECTED: "New Addon personal and pro memberships one on one mentorship selected",
        NEW_ADDON_FEATURE_PERSONAL_AND_PRO_MEMBERSHIP_ONE_ON_ONE_MENTORSHIP_REMOVED: "New Addon personal and pro memberships one on one removed",
        CERTIFICATE_AND_PERSONALISE_BUTTON_CLICKED: "Personalise and certificate button clicked",
        CERTIFICATE_BUTTON_CLICKED: 'Certificate button clicked',

        // Post Payment feature
        NEW_POST_PAYMENT_FEATURE_PLACEMENT_TAB_SELECTED: "New post payment placement feature tab selected",
        NEW_POST_PAYMENT_FEATURE_LEARNING_TAB_SELECTED: "New post payment learning feature tab selected",
        NEW_POST_PAYMENT_FEATURE_LOADED: "New post payment feature loaded",
        NEW_POST_PAYMENT_FEATURE_LEARNING_PACK_SELECTED: "New post payment feature learning pack selected",
        NEW_POST_PAYMENT_FEATURE_PLACEMENT_PACK_SELECTED: "New post payment feature placement pack selected",
        NEW_POST_PAYMENT_FEATURE_PLACEMENT_ADD_ON_SELECTED: "New post payment feature placement pack add on selected",
        NEW_POST_PAYMENT_FEATURE_MENTORSHIP_ADD_ON_SELECTED: "New post payment feature mentorship add on selected",
        NEW_POST_PAYMENT_FEATURE_LEARNING_PACK_ADD_ON_SELECTED: "New post payment feature learning pack add on selected",
        NEW_POST_PAYMENT_FEATURE_LEARNING_PACK_BOTTOM_STICKY_SELECTED: "New post payment feature learning pack bottom sticky selected",
        NEW_POST_PAYMENT_FEATURE_PLACEMENT_PACK_BOTTOM_STICKY_SELECTED: "New post payment feature placement pack bottom sticky selected",
        NEW_POST_PAYMENT_FEATURE_CERTIFICATE_SHARE_BUTTON_CLICKED: "New post payment feature certificate share button clicked",
        NEW_POST_PAYMENT_FEATURE_CERTIFICATE_DOWNLOAD_BUTTON_CLICKED: "New post payment feature certificate download button clicked",
        NEW_POST_PAYMENT_FEATURE_PERSONALISE_SUBJECT_ADDON_CLICKED: "New post payment feature personalise addon selected",
        NEW_POST_PAYMENT_FEATURE_PERSONALISE_SUBJECT_ADDON_REMOVED: "New post payment feature personalise addon removed",
        NEW_POST_PAYMENT_FEATURE_CV_SHARING_ADDON_CLICKED: "New post payment feature CV sharing addon selected",
        NEW_POST_PAYMENT_FEATURE_CV_SHARING_ADDON_REMOVED: "New post payment feature CV sharing addon removed",
        NEW_POST_PAYMENT_FEATURE_PRACTICE_INTERVIEW_ADDON_CLICKED: "New post payment feature practice interview addon selected",
        NEW_POST_PAYMENT_FEATURE_PRACTICE_INTERVIEW_ADDON_REMOVED: "New post payment feature practice interview addon removed",
        NEW_POST_PAYMENT_FEATURE_INSTANT_CERTIFICATE_ADDON_CLICKED: "New post payment feature instant certificate addon selected",
        NEW_POST_PAYMENT_FEATURE_INSTANT_CERTIFICATE_ADDON_REMOVED: "New post payment feature instant certificate addon removed",
        NEW_POST_PAYMENT_FEATURE_CV_REVAMP_ADDON_CLICKED: "New post payment feature CV revamp addon selected",
        NEW_POST_PAYMENT_FEATURE_CV_REVAMP_ADDON_REMOVED: "New post payment feature CV revamp addon removed",

        // Thank You page after purchasing current certificate course
        THANK_YOU_PAGE_LOADED: "Thank You Page Loaded After Purchase",

        // New Post payment feature
        NEW_POST_CERTIFICATE_PURCHASE_PAGE_NORMAL_BUTTON_CLICKED: "New Post Certificate Purchase Page Normal Buy Button Clicked",
        NEW_POST_CERTIFICATE_PURCHASE_PAGE_STICKY_BUTTON_CLICKED: "New Post Certificate Purchase Page Sticky Buy Button Clicked",
        NEW_POST_CERTIFICATE_PURCHASE_PAGE_NORMAL_CONTACT_COUNSELLOR_BUTTON_CLICKED: "New Post Certificate Purchase Page Normal Contact Counsellor Button Clicked",
        NEW_POST_CERTIFICATE_PURCHASE_PAGE_STICKY_CONTACT_COUNSELLOR_BUTTON_CLICKED: "New Post Certificate Purchase Page Sticky Contact Counsellor Button Clicked",
        NEW_POST_CERTIFICATE_PURCHASE_PAGE_EDIT_USER_GOAL_BUTTON_CLICKED: "New Post Certificate Purchase Page Edit User Goal Button Clicked",
        NEW_POST_CERTIFICATE_PURCHASE_PAGE_VIEW_SYLLABUS_BUTTON_CLICKED: "New Post Certificate Purchase Page View Syllabus Button Clicked",
        NEW_POST_CERTIFICATE_PURCHASE_PAGE_VIEW_PROJECTS_BUTTON_CLICKED: "New Post Certificate Purchase Page View Projects Button Clicked",
        NEW_POST_CERTIFICATE_PURCHASE_PAGE_VIEW_PLACEMENT_MODAL_BUTTON_CLICKED: "New Post Certificate Purchase Page View Placement Modal Button Clicked",

        //New Pack Approach
        NEW_PACK_PAGE_LOADED: 'New Pack Page Loaded',
        BEGINNER_PACK_SELECTED: 'Beginner Pack Selected',
        INTERMEDIATE_PACK_SELECTED: 'Intermediate Pack Selected',
        ADVANCE_PACK_SELECTED: 'Advance Pack Selected',
        BEGINNER_PACK_REMOVED: 'Beginner Pack Removed',
        INTERMEDIATE_PACK_REMOVED: 'Intermediate Pack Removed',
        ADVANCE_PACK_REMOVED: 'Advance Pack Removed',
        NEW_PACK_PAGE_SKIP_ADD_ON_BUTTON_CLICKED: 'New Pack Page Skipp Add-On Button Clicked',
        NEW_PACK_PAGE_CONTINUE_WITH_ONE_CERTIFICATE: 'New Page Continue With One Certificate Button Clicked',
        NEW_PACK_PAGE_STICKY_BAR_BUTTON_CLICKED: 'New Page Sticky Bar Button Clicked',
        BEGINNER_PACK_DETAILS_EXPANDED: 'Beginner Pack Details Expanded',
        INTERMEDIATE_PACK_DETAILS_EXPANDED: 'Intermediate Pack Details Expanded',
        ADVANCE_PACK_DETAILS_EXPANDED: 'Advance Pack Details Expanded',
        BEGINNER_PACK_DETAILS_CLOSED: 'Beginner Pack Details Closed',
        INTERMEDIATE_PACK_DETAILS_CLOSED: 'Intermediate Pack Details Closed',
        ADVANCE_PACK_DETAILS_CLOSED: 'Advance Pack Details Closed',
        BEGINNER_PACK_PAYMENT_BUTTON: 'Beginner Pack Payment Button Clicked',
        INTERMEDIATE_PACK_PAYMENT_BUTTON: 'Intermediate Pack Payment Button Clicked',
        ADVANCE_PACK_PAYMENT_BUTTON: 'Advance Pack Payment Button Clicked',

        //Custom Bundle Page
        CUSTOM_BUNDLE_PAGE_LOADED: 'Custom Bundle Page Loaded',
        CUSTOM_BUNDLE_CREATED: 'Custom Bundle Created',
        STICKY_BAR_CREATE_BUNDLE_BUTTON_CLICKED: 'Sticky Bar Create Bundle Button Clicked',

        // Bundle Approach
        NEW_ADDON_FEATURE_BUNDLE_SELECTION_PAGE_LOADED: 'New Add-On Page Bundle Selection Page Loaded',
        NEW_ADDON_FEATURE_BUNDLE_SELECTION_PAGE_SUGGESTED_SKILL_MODAL: 'New Add-On Page Bundle Selection Page View Suggested Skill Modal Opened',
        NEW_ADDON_FEATURE_BUNDLE_SELECTION_STICKY_BAR_REMOVE_ADD_ON: 'New Add-On Feature Bundle Selection Sticky Bar Remove Add-On Clicked',
        
        // Others
        NEW_ADDON_FEATURE_PERSONAL_AND_PRO_MEMBERSHIP_PAGE_PLACEMENT_MODAL: 'New Add-on Personal and Pro Membership Page Placement Modal Opened',
        NEW_ADDON_FEATURE_PERSONAL_AND_PRO_MEMBERSHIP_PAGE_COURSE_SYLLABUS_MODAL: 'New Add-on Personal and Pro Membership Page Personalise Course Syllabus Modal Opened',
        NEW_ADDON_FEATURE_PERSONAL_AND_PRO_MEMBERSHIP_PAGE_COURSE_MODAL: 'New Add-on Personal and Pro Membership Page Course Category Modal Opened',

        //New Post Payment Screens
        NEW_GOAL_TAKING_SCREEN_LOADED: "New User Goal Taking Screen Loaded",
        NEW_GOAL_SCREEN_USER_SELECTED_PROFESSIONAL_EXPERIENCE: "New User Goal Screen User Selected Professional Experience",
        NEW_GOAL_SCREEN_USER_SELECTED_IMMEDIATE_CAREER_GOAL: "New User Goal Screen User Selected Immediate Career Goal",
        NEW_GOAL_SCREEN_USER_SUBMITTED_GOAL_DETAILS: "New User Goal Screen User Submitted Goal Details",

        //New Certificate Download
        NEW_CERTIFICATE_DOWNLOAD_PAGE_LOADED: "New Certificate Download Page Loaded",
        NEW_CERTIFICATE_DOWNLOAD_PAGE_DOWNLOAD_BUTTON_CLICKED: "New Certificate Download Page Download Button Clicked",
        NEW_CERTIFICATE_DOWNLOAD_PAGE_SHARE_BUTTON_CLICKED: "New Certificate Download Page LinkedIn Share Button Clicked",
        NEW_CERTIFICATE_DOWNLOAD_PAGE_COPY_LINK_BUTTON_CLICKED: "New Certificate Download Page Copy Link Button Clicked",

        NEW_POST_CERTIFICATE_PURCHASE_PAGE_LOADED: "New Post Certificate Purchase Page Loaded",
        NEW_POST_CERTIFICATE_PURCHASE_PAGE_SUGGESTED_BUNDLE_LOADED: "New Post Certificate Purchase Page Suggested Bundle Loaded",
        NEW_POST_CERTIFICATE_PURCHASE_PAGE_UNLOCK_REWARD_NOW_BUTTON_CLICKED: "New Post Certificate Purchase Page Unlock Reward Now Button Clicked"
    }
}

export const coursesList = [
  'Programming', 'Digital Marketing', 'Data Science & Analytics', 'Design, Art & Tools', 'Finance & Investments',  'UI/UX', 'Bussiness & Management', 'Language', 'Cloud Computing', 'Operating System and Databases', 'Hardware and Network Security', 'Film Making', 'Development Tools and No Code', 'Web 3', 'Music'
]

export const qualityLevel = [[0, 10, 10], [10, 20, 30], [20, 30, 40], [30, 40, 50]]

export const qualityLevel2 = [1, 2, 3]

export const courses = [
    {
        id: 1,
        name: 'Programming',
        course_slug: 'programming-courses',
        image: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Coding_1.svg?updatedAt=1688364023282",
        mskill_count: 749
    },
    {
        id: 2,
        name: 'Digital Marketing',
        course_slug: 'digital-marketing-courses',
        image: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Digital_Marketing_25.svg?updatedAt=1688364137460",
        mskill_count: 224
    },
    {
        id: 3,
        name: 'Data Science & Analytics',
        course_slug: 'data-science-analytics-courses',
        image: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Data_Science_1.svg?updatedAt=1688364191116",
        mskill_count: 305
    },
    {
        id: 4,
        name: 'Design, Art & Tools',
        course_slug: 'design-art-tools-courses',
        image: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Pencil_with_Ruler.svg?updatedAt=1688364239209",
        mskill_count: 412
    },
    {
        id: 5,
        name: 'Finance & Investments',
        course_slug: 'finance-investments-courses',
        image: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/finance_1.svg?updatedAt=1688364280208",
        mskill_count: 153
    },
    {
        id: 6,
        name: 'UI/UX',
        course_slug: 'uiux-courses',
        image: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/UI_UX_Design_1.svg?updatedAt=1688364322372",
        mskill_count: 82
    },
    {
        id: 7,
        name: 'Business & Management',
        course_slug: 'business-management-courses',
        image: "https://ik.imagekit.io/238r9emirjj/LinkdinUsers/Business.svg?updatedAt=1701415944525",
        mskill_count: 244
    },
    {
        id: 8,
        name: 'Language',
        course_slug: 'language-courses',
        image: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Language_Translate_1.svg?updatedAt=1688364410223",
        mskill_count: 45
    },
    {
        id: 9,
        name: 'Cloud Computing',
        course_slug: 'cloud-computing-courses',
        image: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Cloud_Computing_1.svg?updatedAt=1688364460111",
        mskill_count: 123
    },
    {
        id: 10,
        name: 'Operating System and Databases',
        course_slug: 'operating-system-and-databases-courses',
        image: "https://ik.imagekit.io/238r9emirjj/LinkdinUsers/Database.svg?updatedAt=1701415944394",
        mskill_count: 114
    },
    {
        id: 11,
        name: 'Hardware and Network Security',
        course_slug: 'hardware-and-network-security-courses',
        image: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Hardware_1.svg?updatedAt=1688364553384",
        mskill_count: 99
    },
    {
        id: 12,
        name: 'Film Making',
        course_slug: 'film-making-courses',
        image: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/film_making_1.svg?updatedAt=1688364620738",
        mskill_count: 76
    },
    {
        id: 13,
        name: 'Development Tools and No Code',
        course_slug: 'development-tools-and-no-code-courses',
        image: "https://ik.imagekit.io/238r9emirjj/LinkdinUsers/No%20Code%20Development%20Platform.svg?updatedAt=1701416015216",
        mskill_count: 177
    },
    {
        id: 14,
        name: 'Web 3',
        course_slug: 'web-3-courses',
        image: "https://ik.imagekit.io/238r9emirjj/LinkdinUsers/NFT%20technology.svg?updatedAt=1701416015062",
        mskill_count: 14
    },
    {
        id: 15,
        name: 'Music',
        course_slug: 'music-courses',
        image: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Music_Sign_1.svg?updatedAt=1688364728768",
        mskill_count: 82
    }
]

export const faqPersonaliseAddon = [
    {
        id: 0,
        question: 'How is this course personalised for me?',
        answer: 'Our domain experts (with 30+ years of combined experience) along with our AI engine analyse your skills, goals & answers to identify what you need to learn. We then build a detailed syllabus accordingly for you. And the personalisation doesn’t end here. Once you start learning, your course will be further personalised to you as per your needs.'

    },
    {
        id: 1,
        question: 'What all courses do you offer?',
        answer: 'Our personalisation technology works across domains, from career skills like Java to even hobbies like baking. Most users use us to learn work related skills in the domain of Programming, Marketing, Data Science, Design, Business & lots of different tools.'

    },
    {
        id: 2,
        question: 'Does the course really help in jobs?',
        answer: 'Yess! If you learn properly, do your assessments & projects and then showcase your skills in your CV & portfolio to employers it will make a huge difference in your job applications & even your own confidence. Our learners work at top companies from Amazon to TCS and many have gone on to become freelancers & entrepreneurs.'

    },
    {
        id: 3,
        question: 'Is there any refund policy?',
        answer: 'There sure is. While completing your course, if you find a cheaper, more personalised course than ours you will get your money back! Just reach out to us within 7 days of purchase with the details on hello@careerninja.in'

    }
]

export const popularSkills = [
    {
        id: 1,
        skill_name: "JavaScript Basic (English)",
        skill_thumbnail: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube-2/infrastructure-94301.png?updatedAt=1688727083984"
    },
    {
        id: 2,
        skill_name: "JavaScript Advanced (English)",
        skill_thumbnail: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube-2/device-94309.png?updatedAt=1688727083691"
    },
    {
        id: 3,
        skill_name: "Java Basic (English)",
        skill_thumbnail: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube-2/router-94311.png?updatedAt=1688727084011",
    },
    {
        id: 4,
        skill_name: "Java Advanced (English)",
        skill_thumbnail: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube-2/mobile-94304.png?updatedAt=1688727085080"
    },
    {
        id: 276,
        skill_name: "C Programming (English)",
        skill_thumbnail: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube-2/communication-94300.png?updatedAt=1688727083586"
    }
]

export const professionalExperiences = [
    {
        name: "Student/Recent Grad.",
        image: "https://ik.imagekit.io/238r9emirjj/post-payment-updates/graduate.svg?updatedAt=1726129364165",
        width: 27.6,
        height: 28
    }, 
    {
        name: "1-5 Yrs Work Exp.",
        image: "https://ik.imagekit.io/238r9emirjj/post-payment-updates/1-5experience.svg?updatedAt=1726129494355",
        width: 26.9,
        height: 28
    }, 
    {
        name: "5-10 Yrs Work Exp.",
        image: "https://ik.imagekit.io/238r9emirjj/post-payment-updates/Group%2022055.svg?updatedAt=1727157941985",
        width: 28,
        height: 28
    }, 
    {
        name: "10+ Yrs Work Exp.",
        image: "https://ik.imagekit.io/238r9emirjj/post-payment-updates/Group%2022057.svg?updatedAt=1727158029128",
        width: 27,
        height: 29
    }
]

export const studentCareerGoal = [
    {
        name: "Get A Good Job",
        image: "https://ik.imagekit.io/238r9emirjj/post-payment-updates/googJob.svg?updatedAt=1726133556799",
        width: 31.62,
        height: 28
    }, 
    {
        name: "Learn New Skills",
        image: "https://ik.imagekit.io/238r9emirjj/post-payment-updates/learnNewSkill.svg?updatedAt=1726133657940",
        width: 28,
        height: 28
    }, 
    {
        name: "Improve CV",
        image: "https://ik.imagekit.io/238r9emirjj/post-payment-updates/Vector%20(2).svg?updatedAt=1727160790319",
        width: 24,
        height: 28
    },
]

export const professionalCareerGoal = [
    {
        name: "Get A Promotion",
        image: "https://ik.imagekit.io/238r9emirjj/post-payment-updates/getPromotion.svg?updatedAt=1726135113037",
        width: 22.7,
        height: 28
    },
    {
        name: "Get A Good Job",
        image: "https://ik.imagekit.io/238r9emirjj/post-payment-updates/googJob.svg?updatedAt=1726133556799",
        width: 31.6,
        height: 28
    },
    {
        name: "Change Career Path",
        image: "https://ik.imagekit.io/238r9emirjj/post-payment-updates/careerPath.svg?updatedAt=1726134778987",
        width: 29.1,
        height: 28
    }, 
    {
        name: "Learn New Skills",
        image: "https://ik.imagekit.io/238r9emirjj/post-payment-updates/learnNewSkill.svg?updatedAt=1726133657940",
        width: 28,
        height: 28
    }, 
]

export const careerGoals = [
    "Get A Good Job", "Switch Career Path", "Get A Promotion", "Learn New Skills"
]

export const popularRoles = [
    "Software Developer", "Data Scientist", "Project Manager", "Product Manager", "Marketing Manager", "Financial Analyst", "UX/UI Designer", "Cybersecurity Specialist"
]

export const iconList = [
    "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube-2/router-94311.png?updatedAt=1688727084011",
    "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube-2/mobile-94304.png?updatedAt=1688727085080",
    "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube-2/infrastructure-94301.png?updatedAt=1688727083984",
    "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube-2/configure-94308.png?updatedAt=1688727083711",
    "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube-2/chip-94294.png?updatedAt=1688727079774",
    "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube/atom-101491.png?updatedAt=1688726932512",
    "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube-2/cogwheel-94296.png?updatedAt=1688727079230",
    "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube/attract-101478.png?updatedAt=1688726931998",
    "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube/atom-101488.png?updatedAt=1688726932206",
    "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube-3/d-101398.png?updatedAt=1688727215016",
    "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube/blackboard-101481.png?updatedAt=1688726932551",
    "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube-3/art-101400.png?updatedAt=1688727204601",
    "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube-3/access-101411.png?updatedAt=1688727204514",
    "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube-3/art-101400.png?updatedAt=1688727204601",
    "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube-3/app-101402.png?updatedAt=1688727203802",
    "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube-3/art-101405.png?updatedAt=1688727204140",
    "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube-3/art-101409.png?updatedAt=1688727203891",
    "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube-4/balance-94453.png?updatedAt=1688727390531",
    "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube-5/strip-94370.png?updatedAt=1688727498391",
    "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube-5/diagram-94367.png?updatedAt=1688727498311",
    "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube-5/art-94360.png?updatedAt=1688727493725",
    "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/backup-94310.png?updatedAt=1688726544823",
    "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube/explore-101477.png?updatedAt=1688726937840",
    "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube/bulb-101472.png?updatedAt=1688726936304",
    "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube/broadcast-101487.png?updatedAt=1688726932946",
    "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube-2/infrastructure-94301.png?updatedAt=1688727083984",
    "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube-2/router-94311.png?updatedAt=1688727084011",
    "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube-2/mobile-94304.png?updatedAt=1688727085080",
    "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube-2/infrastructure-94301.png?updatedAt=1688727083984",
    "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube-2/configure-94308.png?updatedAt=1688727083711",
    "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube-2/chip-94294.png?updatedAt=1688727079774",
    "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube/atom-101491.png?updatedAt=1688726932512",
    "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube-2/cogwheel-94296.png?updatedAt=1688727079230",
    "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube/attract-101478.png?updatedAt=1688726931998",
    "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube/atom-101488.png?updatedAt=1688726932206",
    "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube-3/d-101398.png?updatedAt=1688727215016",
    "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube/blackboard-101481.png?updatedAt=1688726932551",
    "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube-3/art-101400.png?updatedAt=1688727204601",
    "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube-3/access-101411.png?updatedAt=1688727204514",
    "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube-3/art-101400.png?updatedAt=1688727204601",
    "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube-3/app-101402.png?updatedAt=1688727203802",
    "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube-3/art-101405.png?updatedAt=1688727204140",
    "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube-3/art-101409.png?updatedAt=1688727203891",
    "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube-4/balance-94453.png?updatedAt=1688727390531",
    "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube-5/strip-94370.png?updatedAt=1688727498391",
    "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube-5/diagram-94367.png?updatedAt=1688727498311",
    "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube-5/art-94360.png?updatedAt=1688727493725",
    "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/backup-94310.png?updatedAt=1688726544823",
    "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube/explore-101477.png?updatedAt=1688726937840",
    "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube/bulb-101472.png?updatedAt=1688726936304",
    "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube/broadcast-101487.png?updatedAt=1688726932946",
    "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube-2/infrastructure-94301.png?updatedAt=1688727083984"
]

export const companyIcons = [
    'https://ik.imagekit.io/238r9emirjj/dataschool/image%20340.png?updatedAt=1707912130704',
    'https://ik.imagekit.io/238r9emirjj/dataschool/image%20343.png?updatedAt=1707912130707',
    'https://ik.imagekit.io/238r9emirjj/dataschool/image%20344.png?updatedAt=1707912130695',
    'https://ik.imagekit.io/238r9emirjj/dataschool/image%20345.png?updatedAt=1707912130762',
    'https://ik.imagekit.io/238r9emirjj/dataschool/Paytm%20-%20jpeg%200.png?updatedAt=1707912130698'
]

export const ourLearnersLove = [
    {
        id: 1,
        name: "DEVENDRA KUMAR",
        image: "https://xgfy-czuw-092q.m2.xano.io/vault/OXY-0ZPV/baXEcb1XPmnis57MeW-H9qGFd4o/4lN0Kw../fake48838.jpg",
        content: '"Credit to LearnTube, I transformed from an engineer to a part-time data analyst earning 80-90k and leading an internationally recognized Auto Pneumatic System project"'
    },
    {
        id: 2,
        name: "Nilambar Giri",
        image: "https://xgfy-czuw-092q.m2.xano.io/vault/OXY-0ZPV/ph839iZMfBhV7-IC3r7D2MvBcms/Meff0A../236490.jpg",
        content: '"LearnTube empowered my dream startup. I mastered JavaScript and Product Management and raised funding for SavePae. You turned my aspirations into reality as a middle-class dreamer."',
        skill: "Javascript Personalised Course"
    },
    {
        id: 3,
        name: "Bhavmeet Kaur",
        image: "https://xgfy-czuw-092q.m2.xano.io/vault/OXY-0ZPV/iW3EiVDEHFOILH543H-zv902nVc/MYUOSQ../438935.jpg",
        content: '"Thanks to LearnTube, I upgraded my SEO skills at Ablysoft. Your transformative power helped me secure a Management Trainee position at Reliance Retail with a 24LPA salary"',
        skill: "SEO Personalised Course"
    },
    {
        id: 4,
        name: "Kishore V",
        image: "https://xgfy-czuw-092q.m2.xano.io/vault/OXY-0ZPV/uBeomyjcMRfMFuyvqOshR-fUXvo/oDc3QQ../fake602695.jpeg",
        content: '"LearnTube’s Figma Prototyping course unlocked my creative potential and helped me build an Instagram clone. I’ve found my passion and am ready to scale my career in the design world!"',
        skill: "Figma Design Personalised Course"
    },
    {
        id: 5,
        name: "Prithish Bhardwaj",
        image: "https://xgfy-czuw-092q.m2.xano.io/vault/OXY-0ZPV/wLAxWaaWeNfvTMQASPDLm9UJyvw/27dy6A../14017.jpg",
        content: '"Discovering LearnTube in my final MBBS year was a game-changer. Despite battling cancer, I still managed to achieve financial success with a 10 LPA salary. All thanks to LearnTube’s learning."',
        skill: "System Design Personalised Course"
    },
]

export const skills = [
        [
            {
                id: 273,
                skill_name: "Java Basic (English)",
                skill_slug: "java-basic-english-course",
                skill_thumbnail: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube-2/router-94311.png?updatedAt=1688727084011",
            },
            {
                id: 265,
                skill_name: "Java Advanced (English)",
                skill_slug: "java-advanced-course",
                skill_thumbnail: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube-2/mobile-94304.png?updatedAt=1688727085080"
            },
            {
                id: 275,
                skill_name: "HTML (English)",
                skill_slug: "html-english-course",
                skill_thumbnail: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube-2/international-94303.png?updatedAt=1688727086351"
            },
            {
                id: 283,
                skill_name: "JavaScript Basic (English)",
                skill_slug: "javascript-basic-english-course",
                skill_thumbnail: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube-2/infrastructure-94301.png?updatedAt=1688727083984"
            },
            {
                id: 296,
                skill_name: "JavaScript Advanced (English)",
                skill_slug: "javascript-advanced-english-course",
                skill_thumbnail: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube-2/device-94309.png?updatedAt=1688727083691"
            },
            {
                id: 276,
                skill_name: "C Programming (English)",
                skill_slug: "c-programming-english-course",
                skill_thumbnail: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube-2/communication-94300.png?updatedAt=1688727083586"
            },
            {
                id: 282,
                skill_name: "C++ Programming (English)",
                skill_slug: "cplusplus-programming-english-course",
                skill_thumbnail: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube-2/connection-94295.png?updatedAt=1688727083576"
            },
            {
                id: 314,
                skill_name: "C# Basic (English)",
                skill_slug: "c-basic-english-course",
                skill_thumbnail: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube-2/configure-94308.png?updatedAt=1688727083711"
            },
            {
                id: 89,
                skill_name: "C# Intermediate",
                skill_slug: "c-intermediate-course",
                skill_thumbnail: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube-2/folder-94302.png?updatedAt=1688727083658"
            },
            {
                id: 90,
                skill_name: "C# Advanced",
                skill_slug: "c-advanced-course",
                skill_thumbnail: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube-2/communication-94297.png?updatedAt=1688727083019"
            },
            {
                id: 308,
                skill_name: "Django (Basic)",
                skill_slug: "django-basic-course",
                skill_thumbnail: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube-2/cloud-94305.png?updatedAt=1688727079684"
            },
            {
                id: 100,
                skill_name: "Django Advanced",
                skill_slug: "django-advanced-course",
                skill_thumbnail: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube-2/cloud-94307.png?updatedAt=1688727079751"
            },
            {
                id: 323,
                skill_name: "PHP (English)",
                skill_slug: "php-english-course",
                skill_thumbnail: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube-2/chip-94294.png?updatedAt=1688727079774"
            },
            {
                id: 127,
                skill_name: "Angular JS",
                skill_slug: "angular-js-course",
                skill_thumbnail: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube-2/access-94298.png?updatedAt=1688727079504"
            },
            {
                id: 128,
                skill_name: "jQuery",
                skill_slug: "jquery-course",
                skill_thumbnail: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube-2/access-94292.png?updatedAt=1688727079556"
            },
            {
                id: 134,
                skill_name: "Bootstrap",
                skill_slug: "bootstrap-course",
                skill_thumbnail: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube-2/cloud-94306.png?updatedAt=1688727079443"
            },
            {
                id: 153,
                skill_name: "Laravel",
                skill_slug: "laravel-course",
                skill_thumbnail: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube-2/cloud-94293.png?updatedAt=1688727079369"
            },
            {
                id: 266,
                skill_name: "Android App Development for Beginners",
                skill_slug: "android-app-development-for-beginners-course",
                skill_thumbnail: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube-5/blueprint-94357.png?updatedAt=1688727494104"
            },
            {
                id: 197,
                skill_name: "Flask",
                skill_slug: "flask-course",
                skill_thumbnail: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube-2/backup-94310.png?updatedAt=1688727079205"
            },
            {
                id: 204,
                skill_name: "TensorFlow",
                skill_slug: "tensorflow-course",
                skill_thumbnail: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube-2/cogwheel-94296.png?updatedAt=1688727079230"
              
            },
            {
                id: 278,
                skill_name: "Data Structure and Algorithm",
                skill_slug: "data-structure-and-algorithm-course",
                skill_thumbnail: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube-2/chart-94299.png?updatedAt=1688727078984"
            },
            {
                id: 220,
                skill_name: "Flutter",
                skill_slug: "flutter-course",
                skill_thumbnail: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube/atom-101491.png?updatedAt=1688726932512"
            },
            {
                id: 221,
                skill_name: "Kotlin",
                skill_slug: "kotlin-course",
                skill_thumbnail: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube/biology-101476.png?updatedAt=1688726932836"
            },
            {
                id: 224,
                skill_name: "CodeIgniter",
                skill_slug: "codeigniter-course",
                skill_thumbnail: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube/atom-101488.png?updatedAt=1688726932206"
            },
            {
                id: 230,
                skill_name: "Object Oriented Analysis and Design",
                skill_slug: "object-oriented-analysis-and-design-course",
                skill_thumbnail: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube/biohazard-101494.png?updatedAt=1688726932343"
            },
            {
                id: 231,
                skill_name: "Redux",
                skill_slug: "redux-course",
                skill_thumbnail: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube/explore-101477.png?updatedAt=1688726937840"
            },
            {
                id: 285,
                skill_name: "React JS",
                skill_slug: "react-js-course",
                skill_thumbnail: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube/earth-101473.png?updatedAt=1688726937920"
            },
            {
                id: 235,
                skill_name: "PHP (Hindi)",
                skill_slug: "php-(hindi)-course",
                skill_thumbnail: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube/experiment-101490.png?updatedAt=1688726937682"
            },
            {
                id: 319,
                skill_name: "Java Basic (Telugu)",
                skill_slug: "java-basic-telugu-course",
                skill_thumbnail: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube/danger-101479.png?updatedAt=1688726936884"
            },
            {
                id: 240,
                skill_name: "Node.JS",
                skill_slug: "node.js-course",
                skill_thumbnail: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube/vector-101475.png?updatedAt=1688726936707"
            },
            {
                id: 312,
                skill_name: "Java Basic (Tamil)",
                skill_slug: "java-basic-tamil-course",
                skill_thumbnail: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube/design-101474.png?updatedAt=1688726936750"
            },
            {
                id: 313,
                skill_name: "Java Basic (Hindi)",
                skill_slug: "java-basic-hindi-course",
                skill_thumbnail: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube/chip-101493.png?updatedAt=1688726936588"
            },
            {
                id: 251,
                skill_name: "Express JS",
                skill_slug: "express-js-course",
                skill_thumbnail: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube/clipboard-101485.png?updatedAt=1688726936464"
            },
            {
                id: 252,
                skill_name: "PostgreSQL",
                skill_slug: "postgresql-course",
                skill_thumbnail: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube/chemistry-101482.png?updatedAt=1688726936501"
            },
            {
                id: 337,
                skill_name: "C++ Programming (Hindi)",
                skill_slug: "cplusplus-programming-hindi-course",
                skill_thumbnail: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube/bulb-101472.png?updatedAt=1688726936304"
            },
            {
                id: 256,
                skill_name: "System Design",
                skill_slug: "system-design-course",
                skill_thumbnail: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube/book-101486.png?updatedAt=1688726932682"
            },
            {
                id: 320,
                skill_name: "CSS (English)",
                skill_slug: "css-english-course",
                skill_thumbnail: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube/blackboard-101481.png?updatedAt=1688726932551"
            },
            {
                id: 336,
                skill_name: "Webflow For Website Creation",
                skill_slug: "webflow-for-website-creation-course",
                skill_thumbnail: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube/biohazard-101494.png?updatedAt=1688726932343"
            },
            {
                id: 343,
                skill_name: "Master DSA in Java Using Collections Framework",
                skill_slug: "master-dsa-in-java-using-collections-framework-course",
                skill_thumbnail: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube/atom-101488.png?updatedAt=1688726932206"
            },
            {
                id: 344,
                skill_name: "Next.js Mastery: Building Modern Web Applications",
                skill_slug: "next-js-mastery-building-modern-web-applications-course",
                skill_thumbnail: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube/biology-101489.png?updatedAt=1688726932395"
            },
            {
                id: 345,
                skill_name: "SpringBoot (English)",
                skill_slug: "springboot-english-course",
                skill_thumbnail: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube/attract-101478.png?updatedAt=1688726931998"
              
            }
        ],
        [
            {
                id: 267,
                skill_name: "Digital Marketing Fundamentals",
                skill_slug: "digital-marketing-fundamentals-course",
                skill_thumbnail: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube/brain-101484.png?updatedAt=1688726932002"
            },
            {
                id: 290,
                skill_name: "SEO (English)",
                skill_slug: "seo-english-course",
                skill_thumbnail: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube-3/game-101401.png?updatedAt=1688727215507"
            },
            {
                id: 291,
                skill_name: "Social Media Marketing (English)",
                skill_slug: "social-media-marketing-english-course",
                skill_thumbnail: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube-3/eye-101399.png?updatedAt=1688727215284"
            },
            {
                id: 303,
                skill_name: "Content Marketing (English)",
                skill_slug: "content-marketing-english-course",
                skill_thumbnail: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube-3/diagram-101416.png?updatedAt=1688727215184"
            },
            {
                id: 38,
                skill_name: "Search Engine Marketing",
                skill_slug: "search-engine-marketing-course",
                skill_thumbnail: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube-3/d-101398.png?updatedAt=1688727215016"
            },
            {
                id: 39,
                skill_name: "Email Marketing",
                skill_slug: "email-marketing-english-course",
                skill_thumbnail: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube-3/copy-101410.png?updatedAt=1688727214574"
            },
            {
                id: 40,
                skill_name: "Web Analytics",
                skill_slug: "web-analytics-course",
                skill_thumbnail: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube-3/computer-101397.png?updatedAt=1688727209753",
            },
            {
                id: 41,
                skill_name: "Account Management & Client Servicing",
                skill_slug: "account-management-client-servicing-course",
                skill_thumbnail: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube-3/content-101407.png?updatedAt=1688727209562"
            },
            {
                id: 42,
                skill_name: "Lead Generation",
                skill_slug: "lead-generation-course",
                skill_thumbnail: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube-3/bulb-101417.png?updatedAt=1688727208821"
            },
            {
                id: 43,
                skill_name: "Branding & Design Essentials",
                skill_slug: "branding-design-essentials-course",
                skill_thumbnail: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube-3/bulb-101415.png?updatedAt=1688727208629"
            },
            {
                id: 44,
                skill_name: "Display Advertisements",
                skill_slug: "display-advertisements-course",
                skill_thumbnail: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube-3/cogwheel-101414.png?updatedAt=1688727208454"
            },
            {
                id: 45,
                skill_name: "Influencer Marketing",
                skill_slug: "influenceers-marketing-course",
                skill_thumbnail: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube-3/calipers-101406.png?updatedAt=1688727208545"
            },
            {
                id: 46,
                skill_name: "Remarketing & Growth Hacking",
                skill_slug: "remarketing-growth-hacking-course",
                skill_thumbnail: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube-3/brain-101404.png?updatedAt=1688727208281"
            },
            {
                id: 297,
                skill_name: "Affiliate Marketing (English)",
                skill_slug: "affiliate-marketing-english-course",
                skill_thumbnail: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube-3/build-101412.png?updatedAt=1688727207968"
            },
            {
                id: 48,
                skill_name: "CRM & Community Building",
                skill_slug: "crm-community-building-course",
                skill_thumbnail: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube-3/beverage-101419.png?updatedAt=1688727207752"
            },
            {
                id: 150,
                skill_name: "Google Analytics",
                skill_slug: "google-analytics-course",
                skill_thumbnail: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube-3/art-101418.png?updatedAt=1688727206561"
            },
            {
                id: 311,
                skill_name: "Social Media Marketing (Hindi)",
                skill_slug: "social-media-marketing-hindi-course",
                skill_thumbnail: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube-3/art-101395.png?updatedAt=1688727205000"
            },
            {
                id: 247,
                skill_name: "Paid Ads and Paid Media",
                skill_slug: "paid-ads-and-paid-media-course",
                skill_thumbnail: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube-3/art-101408.png?updatedAt=1688727204953"
            },
            {
                id: 248,
                skill_name: "SEO (Hindi)",
                skill_slug: "seo-hindi-course",
                skill_thumbnail: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube-3/access-101411.png?updatedAt=1688727204514"
            },
            {
                id: 322,
                skill_name: "Digital Marketing (Hindi)",
                skill_slug: "digital-marketing-hindi-course",
                skill_thumbnail: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube-3/application-101396.png?updatedAt=1688727204328"
            },
            {
                id: 10000,
                skill_name: "Copywriting",
                skill_slug: "copywriting-course",
                skill_thumbnail: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube-3/art-101400.png?updatedAt=1688727204601"
            },
            {
                id: 10000,
                skill_name: "Growth Marketing",
                skill_slug: "growth-marketing-course",
                skill_thumbnail: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube-3/art-101400.png?updatedAt=1688727204601"
            }
        ],
        [
            {
                id: 274,
                skill_name: "Python Basic (English)",
                skill_slug: "python-basic-english-course",
                skill_thumbnail: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube-3/art-101400.png?updatedAt=1688727204601"
            },
            {
                id: 292,
                skill_name: "Python Advanced (English)",
                skill_slug: "python-advanced-english-course",
                skill_thumbnail: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube-3/art-101405.png?updatedAt=1688727204140"
            },
            {
                id: 287,
                skill_name: "Power BI Basic (English)",
                skill_slug: "power-bi-basic-english-course",
                skill_thumbnail: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube-3/action-101403.png?updatedAt=1688727204221"
            },
            {
                id: 114,
                skill_name: "Deep Learning",
                skill_slug: "deep-learning-course",
                skill_thumbnail: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube-3/art-101409.png?updatedAt=1688727203891"
            },
            {
                id: 289,
                skill_name: "Power BI Advanced (English)",
                skill_slug: "power-bi-advanced-english-course",
                skill_thumbnail: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube-3/app-101402.png?updatedAt=1688727203802"
            },
            {
                id: 271,
                skill_name: "Tableau Basic (English)",
                skill_slug: "tableau-basic-english-course",
                skill_thumbnail: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube-3/art-101413.png?updatedAt=1688727203597"
            },
            {
                id: 110,
                skill_name: "Tableau Advanced",
                skill_slug: "tableau-advanced-course",
                skill_thumbnail: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube-4/report-94467.png?updatedAt=1688727395300"
            },
            {
                id: 293,
                skill_name: "Python Basic (Hindi)",
                skill_slug: "python-basic-hindi-course",
                skill_thumbnail: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube-4/sheild-94457.png?updatedAt=1688727395172"
            },
            {
                id: 294,
                skill_name: "Python Advanced (Hindi)",
                skill_slug: "python-advanced-hindi-course",
                skill_thumbnail: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube-4/patnership-94458.png?updatedAt=1688727394393"
            },
            {
                id: 295,
                skill_name: "Machine Learning",
                skill_slug: "machine-learning-course",
                skill_thumbnail: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube-4/plant-94460.png?updatedAt=1688727394168"
            },
            {
                id: 186,
                skill_name: "R Programming",
                skill_slug: "r-programming-course",
                skill_thumbnail: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube-4/target-94461.png?updatedAt=1688727394244"
            },
            {
                id: 268,
                skill_name: "Data Analytics (English)",
                skill_slug: "data-analytics-english-course",
                skill_thumbnail: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube-4/idea-94459.png?updatedAt=1688727394053"
            },
            {
                id: 309,
                skill_name: "Applied Artificial Intelligence",
                skill_slug: "applied-artificial-intelligence-course",
                skill_thumbnail: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube-4/money-94471.png?updatedAt=1688727393951"
            },
            {
                id: 324,
                skill_name: "Python Basic (Tamil)",
                skill_slug: "python-basic-tamil-course",
                skill_thumbnail: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube-4/money-94470.png?updatedAt=1688727393827"
            },
            {
                id: 259,
                skill_name: "Statistical Modelling",
                skill_slug: "statistical-modelling-course",
                skill_thumbnail: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube-4/money-94469.png?updatedAt=1688727393908"
            },
            {
                id: 264,
                skill_name: "Python (Telugu)",
                skill_slug: "python-telugu-course",
                skill_thumbnail: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube-4/hourglass-94456.png?updatedAt=1688727393223"
            },
            {
                id: 333,
                skill_name: "Mastering ChatGPT - The Ultimate Guide to Conversational AI",
                skill_slug: "mastering-chatgpt-the-ultimate-guide-to-conversational-ai-course",
                skill_thumbnail: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube-4/analysis-94465.png?updatedAt=1688727390446"
            }
        ],
        [
            {
                id: 269,
                skill_name: "Photoshop Basic (English)",
                skill_slug: "photoshop-basic-english-course",
                skill_thumbnail: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube-4/balance-94453.png?updatedAt=1688727390531"
            },
            {
                id: 270,
                skill_name: "Photoshop Advanced (English)",
                skill_slug: "photoshop-advanced-english-course",
                skill_thumbnail: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube-4/briefcase-94468.png?updatedAt=1688727390306"
            },
            {
                id: 342,
                skill_name: "Blender Basic (English)",
                skill_slug: "blender-basic-english-course",
                skill_thumbnail: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube-4/analysis-94452.png?updatedAt=1688727390408"
            },
            {
                id: 77,
                skill_name: "Blender Advanced",
                skill_slug: "blender-advanced-course",
                skill_thumbnail: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube-4/account-94454.png?updatedAt=1688727390135"
            },
            {
                id: 298,
                skill_name: "Beginner's Guide to Illustrator",
                skill_slug: "beginner-s-guide-to-illustrator-course",
                skill_thumbnail: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube-4/finance-94455.png?updatedAt=1688727390727"
            },
            {
                id: 85,
                skill_name: "After Effects",
                skill_slug: "after-effects-english-course",
                skill_thumbnail: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube-4/analysis-94462.png?updatedAt=1688727390018"
            },
            {
                id: 326,
                skill_name: "AutoCAD (English)",
                skill_slug: "autocad-english-course",
                skill_thumbnail: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube-4/funding-94464.png?updatedAt=1688727390028"
            },
            {
                id: 118,
                skill_name: "GIMP",
                skill_slug: "gimp-course",
                skill_thumbnail: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube-4/analysis-94466.png?updatedAt=1688727389841"
            },
            {
                id: 111,
                skill_name: "Premiere Pro Essentials: A Beginner's Guide (English)",
                skill_slug: "premiere-pro-essentials-a-beginner-s-guide-english-course",
                skill_thumbnail: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube-4/accounting-94463.png?updatedAt=1688727389859"
            },
            {
                id: 121,
                skill_name: "Digital Art Basic",
                skill_slug: "digital-art-basic-english-course",
                skill_thumbnail: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube-5/text-94368.png?updatedAt=1688727499394"
            },
            {
                id: 122,
                skill_name: "Digital Art Advanced",
                skill_slug: "digital-art-advanced-course",
                skill_thumbnail: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube-5/strip-94370.png?updatedAt=1688727498391"
            },
            {
                id: 132,
                skill_name: "Krita",
                skill_slug: "krita-course",
                skill_thumbnail: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube-5/diagram-94367.png?updatedAt=1688727498311"
            },
            {
                id: 340,
                skill_name: "Sketching Basics",
                skill_slug: "sketching-basics-course",
                skill_thumbnail: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube-5/idea-94369.png?updatedAt=1688727498258"
            },
            {
                id: 135,
                skill_name: "Wondershare Filmora",
                skill_slug: "wondershare-filmora-course",
                skill_thumbnail: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube-5/design-94366.png?updatedAt=1688727498170"
            },
            {
                id: 138,
                skill_name: "DaVinci Resolve Pre-Beginner",
                skill_slug: "davinci-resolve-pre-beginner-course",
                skill_thumbnail: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube-5/design-94364.png?updatedAt=1688727498157"
            },
            {
                id: 151,
                skill_name: "Brush Pen Calligraphy",
                skill_slug: "brush-pen-calligraphy-course",
                skill_thumbnail: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube-5/work-94371.png?updatedAt=1688727498065"
            },
            {
                id: 152,
                skill_name: "Inkscape",
                skill_slug: "inkscape-course",
                skill_thumbnail: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube-5/compass-94362.png?updatedAt=1688727498013"
            },
            {
                id: 154,
                skill_name: "Solidworks",
                skill_slug: "solidworks-course",
                skill_thumbnail: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube-5/coding-94363.png?updatedAt=1688727497968"
            },
            {
                id: 155,
                skill_name: "Lightroom",
                skill_slug: "lightroom-course",
                skill_thumbnail: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube-5/design-94365.png?updatedAt=1688727497843"
            },
            {
                id: 160,
                skill_name: "Motion Graphics Basic",
                skill_slug: "motion-graphics-basic-course",
                skill_thumbnail: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube-5/box-94358.png?updatedAt=1688727494086"
            },
            {
                id: 288,
                skill_name: "Canva - Graphic Designing",
                skill_slug: "canva-graphic-designing-course",
                skill_thumbnail: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube-5/blueprint-94357.png?updatedAt=1688727494104"
            },
            {
                id: 213,
                skill_name: "Photoshop By Gareth David",
                skill_slug: "photoshop-by-gareth-david-course",
                skill_thumbnail: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube-5/bezier-94361.png?updatedAt=1688727494019"
            },
            {
                id: 207,
                skill_name: "Graphic Designing - Concepts",
                skill_slug: "graphic-designing--concepts-course",
                skill_thumbnail: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube-5/api-94352.png?updatedAt=1688727493902"
            },
            {
                id: 215,
                skill_name: "Embroidery",
                skill_slug: "embroidery-course",
                skill_thumbnail: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube-5/balance-94354.png?updatedAt=1688727493781"
            },
            {
                id: 217,
                skill_name: "Make Up",
                skill_slug: "make-up-course",
                skill_thumbnail: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube-5/art-94360.png?updatedAt=1688727493725"
            },
            {
                id: 222,
                skill_name: "SketchUp",
                skill_slug: "sketchup-course",
                skill_thumbnail: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube-5/brainstorming-94359.png?updatedAt=1688727493618"
            },
            {
                id: 223,
                skill_name: "Substance Painter",
                skill_slug: "substance-painter-course",
                skill_thumbnail: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube-5/api-94353.png?updatedAt=1688727493533"
            },
            {
                id: 225,
                skill_name: "Interior Designing With AutoCAD",
                skill_slug: "interior-designing-with-autocad-course",
                skill_thumbnail: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube-5/application-94356.png?updatedAt=1688727493357"
            },
            {
                id: 227,
                skill_name: "Catia",
                skill_slug: "catia-course",
                skill_thumbnail: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube-5/app-94355.png?updatedAt=1688727493256"
            },
            {
                id: 234,
                skill_name: "Adobe XD",
                skill_slug: "adobe-xd-course",
                skill_thumbnail: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/access-94292.png?updatedAt=1688716203631"
            },
            {
                id: 369,
                skill_name: "Introduction to Maya for Beginners",
                skill_slug: "introduction-to-maya-for-beginners-course",
                skill_thumbnail: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/cloud-94293.png?updatedAt=1688726577600"
            }
        ],
        [
            {
                id: 299,
                skill_name: "Forex Trading",
                skill_slug: "forex-trading-course",
                skill_thumbnail: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/access-94298.png?updatedAt=1688726529998"
            },
            {
                id: 279,
                skill_name: "Stock Market Basics (Hinglish)",
                skill_slug: "stock-market-basics-hinglish-course",
                skill_thumbnail: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/backup-94310.png?updatedAt=1688726544823"
            },
            {
                id: 300,
                skill_name: "Advanced Stock Market Strategies for Experienced Investors (Hinglish)",
                skill_slug: "advanced-stock-market-strategies-for-experienced-investors-hinglish-course",
                skill_thumbnail: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/chart-94299.png?updatedAt=1688726560024"
            },
            {
                id: 332,
                skill_name: "Financial Advisory and Portfolio Management",
                skill_slug: "financial-advisory-and-portfolio-management-course",
                skill_thumbnail: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/cloud-94293.png?updatedAt=1688726577600"
            },
            {
                id: 304,
                skill_name: "Tally (Hindi)",
                skill_slug: "tally-hindi-course",
                skill_thumbnail: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/configure-94308.png?updatedAt=1688726614736"
            },
            {
                id: 339,
                skill_name: "Fundamentals of SAP",
                skill_slug: "fundamentals-of-sap-course",
                skill_thumbnail: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/international-94303.png?updatedAt=1688726645411"
            },
            {
                id: 338,
                skill_name: "Financial Accounting",
                skill_slug: "financial-accounting-course",
                skill_thumbnail: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/action-101483.png?updatedAt=1688726715839"
            },
            {
                id: 194,
                skill_name: "Accounting Documents",
                skill_slug: "accounting-documents-course",
                skill_thumbnail: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/analysis-101492.png?updatedAt=1688726748532"
            },
            {
                id: 195,
                skill_name: "Controlling",
                skill_slug: "controlling-course",
                skill_thumbnail: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/apple-101470.png?updatedAt=1688726776098"
            },
            {
                id: 196,
                skill_name: "Bank Accounting",
                skill_slug: "bank-accounting-course",
                skill_thumbnail: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/astronomy-101471.png?updatedAt=1688726821127"
            },
            {
                id: 229,
                skill_name: "Tally in Hindi By Rahul Chaudhary",
                skill_slug: "tally-in-hindi-by-rahul-chaudhary-course",
                skill_thumbnail: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/atom-101480.png?updatedAt=1688726821267"
            }
        ],
        [
            {
                id: 262,
                skill_name: "UI/UX Fundamentals for Beginners",
                skill_slug: "ui-ux-fundamentals-for-beginners-course",
                skill_thumbnail: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube/explore-101477.png?updatedAt=1688726937840"
            },
            {
                id: 277,
                skill_name: "Careers in UX Design",
                skill_slug: "careers-in-ux-design-course",
                skill_thumbnail: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube/earth-101473.png?updatedAt=1688726937920"
            },
            {
                id: 53,
                skill_name: "Product/ UX Strategy",
                skill_slug: "product-ux-strategy-course",
                skill_thumbnail: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube/experiment-101490.png?updatedAt=1688726937682"
            },
            {
                id: 55,
                skill_name: "Information Architecture",
                skill_slug: "information-architecture-course",
                skill_thumbnail: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube/danger-101479.png?updatedAt=1688726936884"
            },
            {
                id: 57,
                skill_name: "Surveying Data through Card Sorting & Site Maps",
                skill_slug: "surveying-data-through-card-sorting-site-maps-course",
                skill_thumbnail: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube/vector-101475.png?updatedAt=1688726936707"
            },
            {
                id: 58,
                skill_name: "Task Flow & User Flow",
                skill_slug: "task-flow-user-flow-course",
                skill_thumbnail: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube/design-101474.png?updatedAt=1688726936750"
            },
            {
                id: 59,
                skill_name: "Wireframing, Prototyping, & Laws for UX",
                skill_slug: "wireframing,-prototyping,-laws-for-ux-course",
                skill_thumbnail: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube/chip-101493.png?updatedAt=1688726936588"
            },
            {
                id: 61,
                skill_name: "Elements of UI Design",
                skill_slug: "elements-of-ui-design-course",
                skill_thumbnail: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube/clipboard-101485.png?updatedAt=1688726936464"
            },
            {
                id: 63,
                skill_name: "Designer Developer Handoff",
                skill_slug: "designer-developer-handoff-course",
                skill_thumbnail: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube/chemistry-101482.png?updatedAt=1688726936501"
            },
            {
                id: 316,
                skill_name: "Figma",
                skill_slug: "figma-course",
                skill_thumbnail: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube/bulb-101472.png?updatedAt=1688726936304"
            }
        ],
        [
            {
                id: 318,
                skill_name: "Microsoft Excel Basic (English)",
                skill_slug: "microsoft-excel-basic-english-course",
                skill_thumbnail: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube/broadcast-101487.png?updatedAt=1688726932946"
            },
            {
                id: 310,
                skill_name: "Microsoft Excel Advanced (English)",
                skill_slug: "microsoft-excel-advanced-english-course",
                skill_thumbnail: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube/book-101486.png?updatedAt=1688726932682"
            },
            {
                id: 317,
                skill_name: "Mastering Freelancing",
                skill_slug: "mastering-freelancing-course",
                skill_thumbnail: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube/atom-101491.png?updatedAt=1688726932512"
            },
            {
                id: 341,
                skill_name: "Microsoft Word (Basics)",
                skill_slug: "microsoft-word-basics-course",
                skill_thumbnail: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube/biology-101476.png?updatedAt=1688726932836"
            },
            {
                id: 328,
                skill_name: "Product Management (English)",
                skill_slug: "product-management-english-course",
                skill_thumbnail: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube/blackboard-101481.png?updatedAt=1688726932551"
            },
            {
                id: 261,
                skill_name: "Business Intelligence",
                skill_slug: "business-intelligence-course",
                skill_thumbnail: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube/biohazard-101494.png?updatedAt=1688726932343"
            },
            {
                id: 198,
                skill_name: "Microsoft Powerpoint Basic",
                skill_slug: "microsoft-powerpoint-basic-course",
                skill_thumbnail: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube/atom-101488.png?updatedAt=1688726932206"
            },
            {
                id: 199,
                skill_name: "Microsoft Powerpoint Advanced",
                skill_slug: "microsoft-powerpoint-advanced-course",
                skill_thumbnail: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube/biology-101489.png?updatedAt=1688726932395"
            },
            {
                id: 200,
                skill_name: "Google Docs",
                skill_slug: "google-docs-course",
                skill_thumbnail: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube/attract-101478.png?updatedAt=1688726931998"
            },
            {
                id: 208,
                skill_name: "Google Sheets",
                skill_slug: "google-sheets-course",
                skill_thumbnail: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube/brain-101484.png?updatedAt=1688726932002"
            },
            {
                id: 209,
                skill_name: "Google Slides",
                skill_slug: "google-slides-course",
                skill_thumbnail: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube-2/international-94303.png?updatedAt=1688727086351"
            },
            {
                id: 216,
                skill_name: "Microsoft Word Advanced",
                skill_slug: "microsoft-word-advanced-course",
                skill_thumbnail: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube-2/mobile-94304.png?updatedAt=1688727085080"
            },
            {
                id: 228,
                skill_name: "Scrum Master",
                skill_slug: "scrum-master-course",
                skill_thumbnail: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube-2/router-94311.png?updatedAt=1688727084011"
            },
        ],
        [
            {
                id: 281,
                skill_name: "Learn English From Scratch In Hindi",
                skill_slug: "learn-english-from-scratch-in-hindi-course",
                skill_thumbnail: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube-2/infrastructure-94301.png?updatedAt=1688727083984"
            },
            {
                id: 280,
                skill_name: "Mastering English",
                skill_slug: "mastering-english-course",
                skill_thumbnail: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube-2/device-94309.png?updatedAt=1688727083691"
            }
        ],
        [
            {
                id: 306,
                skill_name: "Oracle Cloud Infrastructure Basics",
                skill_slug: "oracle-cloud-infrastructure-basics-course",
                skill_thumbnail: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube-2/communication-94300.png?updatedAt=1688727083586"
            },
            {
                id: 168,
                skill_name: "Oracle Cloud Core Infrastructure Advanced Foundations",
                skill_slug: "oracle-cloud-core-infrastructure-advanced-foundations-course",
                skill_thumbnail: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube-2/connection-94295.png?updatedAt=1688727083576"
            },
            {
                id: 307,
                skill_name: "Amazon Web Services Analytics",
                skill_slug: "amazon-web-services-analytics-course",
                skill_thumbnail: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube-2/folder-94302.png?updatedAt=1688727083658"
            },
            {
                id: 327,
                skill_name: "Amazon Web Services Database",
                skill_slug: "amazon-web-services-database-course",
                skill_thumbnail: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube-2/configure-94308.png?updatedAt=1688727083711"
            },
            {
                id: 325,
                skill_name: "Azure (English)",
                skill_slug: "azure-english-course",
                skill_thumbnail: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube-3/game-101401.png?updatedAt=1688727215507"
            },
            {
                id: 182,
                skill_name: "Docker Basic",
                skill_slug: "docker-basic-course",
                skill_thumbnail: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube-3/eye-101399.png?updatedAt=1688727215284"
            },
            {
                id: 183,
                skill_name: "Docker Advanced",
                skill_slug: "docker-advanced-course",
                skill_thumbnail: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube-3/diagram-101416.png?updatedAt=1688727215184"
            },
            {
                id: 226,
                skill_name: "Linux for DevOps Engineers in Hindi by TrainWithShubham",
                skill_slug: "linux-for-devops-engineers-in-hindi-by-trainwithshubham-course",
                skill_thumbnail: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube-3/d-101398.png?updatedAt=1688727215016"
            },
            {
                id: 260,
                skill_name: "Kubernetes",
                skill_slug: "kubernetes-course",
                skill_thumbnail: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube-3/copy-101410.png?updatedAt=1688727214574"
            }
        ],
        [
            {
                id: 286,
                skill_name: "SQL Basic",
                skill_slug: "sql-basic-course",
                skill_thumbnail: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube-3/computer-101397.png?updatedAt=1688727209753"
            },
            {
                id: 67,
                skill_name: "SQL Advanced",
                skill_slug: "sql-advanced-course",
                skill_thumbnail: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube-3/content-101407.png?updatedAt=1688727209562"
            },
            {
                id: 102,
                skill_name: "MySQL",
                skill_slug: "mysql-course",
                skill_thumbnail: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube-3/bulb-101417.png?updatedAt=1688727208821"
            },
            {
                id: 346,
                skill_name: "Linux Basic (English)",
                skill_slug: "linux-basic-english-course",
                skill_thumbnail: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube-3/bulb-101415.png?updatedAt=1688727208629"
            },
            {
                id: 98,
                skill_name: "Linux Advanced",
                skill_slug: "linux-advanced-course",
                skill_thumbnail: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube-3/cogwheel-101414.png?updatedAt=1688727208454"
            },
            {
                id: 145,
                skill_name: "Shell Scripting",
                skill_slug: "shell-scripting-course",
                skill_thumbnail: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube-3/calipers-101406.png?updatedAt=1688727208545"
            },
            {
                id: 157,
                skill_name: "Mongo DB",
                skill_slug: "mongo-db-course",
                skill_thumbnail: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube-3/brain-101404.png?updatedAt=1688727208281"
            },
            {
                id: 158,
                skill_name: "Redis",
                skill_slug: "redis-course",
                skill_thumbnail: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube-3/build-101412.png?updatedAt=1688727207968"
            }
        ],
        [
            {
                id: 321,
                skill_name: "Arduino",
                skill_slug: "arduino-course",
                skill_thumbnail: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube-3/beverage-101419.png?updatedAt=1688727207752"
            },
            {
                id: 315,
                skill_name: "Cyber Security (English)",
                skill_slug: "cyber-security-english-course",
                skill_thumbnail: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube-3/art-101418.png?updatedAt=1688727206561"
            },
            {
                id: 305,
                skill_name: "Ethical Hacking",
                skill_slug: "ethical-hacking-course",
                skill_thumbnail: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube-3/art-101395.png?updatedAt=1688727205000"
            },
            {
                id: 329,
                skill_name: "5G Wireless Engineer",
                skill_slug: "5g-wireless-engineer-course",
                skill_thumbnail: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube-3/art-101408.png?updatedAt=1688727204953"
            },
            {
                id: 331,
                skill_name: "CCNA",
                skill_slug: "ccna-course",
                skill_thumbnail: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube-3/access-101411.png?updatedAt=1688727204514"
            },
            {
                id: 334,
                skill_name: "Comptia A+",
                skill_slug: "comptia-a-course",
                skill_thumbnail: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube-3/application-101396.png?updatedAt=1688727204328"
            },
            {
                id: 335,
                skill_name: "Comptia N+",
                skill_slug: "comptia-n-course",
                skill_thumbnail: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube-3/art-101400.png?updatedAt=1688727204601"
            }
        ],
        [
            {
                id: 301,
                skill_name: "Film Editing Basics",
                skill_slug: "film-editing-basics-course",
                skill_thumbnail: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube-3/art-101405.png?updatedAt=1688727204140"
            },
            {
                id: 302,
                skill_name: "Elemental Creation Basic of Film Making",
                skill_slug: "elemental-creation-basic-of-film-making-course",
                skill_thumbnail: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube-3/action-101403.png?updatedAt=1688727204221"
            },
            {
                id: 170,
                skill_name: "Theory and Practical Cinematography",
                skill_slug: "theory-and-practical-cinematography-course",
                skill_thumbnail: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube-3/art-101409.png?updatedAt=1688727203891"
            },
            {
                id: 171,
                skill_name: "Sparkling Art of Film Direction",
                skill_slug: "sparkling-art-of-film-direction-course",
                skill_thumbnail: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube-3/app-101402.png?updatedAt=1688727203802"
            },
            {
                id: 172,
                skill_name: "Film Financing and Distribution",
                skill_slug: "film-financing-and-distribution-course",
                skill_thumbnail: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube-3/art-101413.png?updatedAt=1688727203597"
            },
            {
                id: 173,
                skill_name: "Film Advertising and Public Relations",
                skill_slug: "film-advertising-and-public-relations-course",
                skill_thumbnail: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube-4/report-94467.png?updatedAt=1688727395300"
            },
            {
                id: 174,
                skill_name: "Corporate Communications in Film Making",
                skill_slug: "corporate-communications-in-film-making-course",
                skill_thumbnail: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube-4/sheild-94457.png?updatedAt=1688727395172"
            },
            {
                id: 175,
                skill_name: "Film Production Design",
                skill_slug: "film-production-design-course",
                skill_thumbnail: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube-4/patnership-94458.png?updatedAt=1688727394393"
            },
            {
                id: 176,
                skill_name: "Film Theories and its Societal Impact",
                skill_slug: "film-theories-and-its-societal-impact-course",
                skill_thumbnail: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube-4/plant-94460.png?updatedAt=1688727394168"
            },
            {
                id: 177,
                skill_name: "Sound Production and Design",
                skill_slug: "sound-production-and-design-course",
                skill_thumbnail: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube-4/target-94461.png?updatedAt=1688727394244"
            },
            {
                id: 178,
                skill_name: "Graphical Animation of Films with Communication Research",
                skill_slug: "graphical-animation-of-films-with-communication-research-course",
                skill_thumbnail: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube-4/idea-94459.png?updatedAt=1688727394053"
            },
            {
                id: 179,
                skill_name: "Sound Designing on Set",
                skill_slug: "sound-designing-on-set-course",
                skill_thumbnail: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube-4/money-94471.png?updatedAt=1688727393951"
            },
            {
                id: 181,
                skill_name: "Story, Scripting and Story Boarding",
                skill_slug: "story,-scripting-and-story-boarding-course",
                skill_thumbnail: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube-4/money-94470.png?updatedAt=1688727393827"
            }
        ],
        [
            {
                id: 166,
                skill_name: "Oracle Application Express – Application Development Foundations",
                skill_slug: "oracle-application-express-–-application-development-foundations-course",
                skill_thumbnail: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube-4/money-94469.png?updatedAt=1688727393908"
            },
            {
                id: 117,
                skill_name: "Git",
                skill_slug: "git-course",
                skill_thumbnail: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube-4/hourglass-94456.png?updatedAt=1688727393223"
            },
            {
                id: 161,
                skill_name: "Salesforce App Builder and Administrator",
                skill_slug: "salesforce-app-builder-and-administrator-course",
                skill_thumbnail: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube-4/analysis-94465.png?updatedAt=1688727390446"
            },
            {
                id: 68,
                skill_name: "WordPress",
                skill_slug: "wordpress-course",
                skill_thumbnail: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube-4/balance-94453.png?updatedAt=1688727390531"
            },
            {
                id: 164,
                skill_name: "Jenkins",
                skill_slug: "jenkins-course",
                skill_thumbnail: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube-4/briefcase-94468.png?updatedAt=1688727390306"
            },
            {
                id: 107,
                skill_name: "Android Studio",
                skill_slug: "android-studio-course",
                skill_thumbnail: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube-4/analysis-94452.png?updatedAt=1688727390408"
            },
            {
                id: 146,
                skill_name: "WIX",
                skill_slug: "wix-course",
                skill_thumbnail: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube-4/account-94454.png?updatedAt=1688727390135"
            },
            {
                id: 147,
                skill_name: "Unreal Engine",
                skill_slug: "unreal-engine-course",
                skill_thumbnail: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube-4/finance-94455.png?updatedAt=1688727390727"
            },
            {
                id: 149,
                skill_name: "Joomla",
                skill_slug: "joomla-course",
                skill_thumbnail: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube-4/analysis-94462.png?updatedAt=1688727390018"
            },
            {
                id: 156,
                skill_name: "Jira",
                skill_slug: "jira-course",
                skill_thumbnail: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube-4/funding-94464.png?updatedAt=1688727390028"
            },
            {
                id: 263,
                skill_name: "Software Testing",
                skill_slug: "software-testing-course",
                skill_thumbnail: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube-4/analysis-94466.png?updatedAt=1688727389841"
            }
        ],
        [
            {
                id: 272,
                skill_name: "Blockchain Fundamentals",
                skill_slug: "blockchain-fundamentals-course",
                skill_thumbnail: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube-4/accounting-94463.png?updatedAt=1688727389859"
            }
        ],
        [
            {
                id: 103,
                skill_name: "Piano",
                skill_slug: "piano-english-course",
                skill_thumbnail: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube-5/strip-94370.png?updatedAt=1688727498391"
            },
            {
                id: 104,
                skill_name: "Guitar Basics: A Comprehensive Guide for Beginners",
                skill_slug: "guitar-basics-a-comprehensive-guide-for-beginners-course",
                skill_thumbnail: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube-5/diagram-94367.png?updatedAt=1688727498311"
            },
            {
                id: 105,
                skill_name: "Guitar Advanced",
                skill_slug: "guitar-advanced-course",
                skill_thumbnail: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube-5/idea-94369.png?updatedAt=1688727498258"
            },
            {
                id: 124,
                skill_name: "Piano Crash Course",
                skill_slug: "piano-crash-course-course",
                skill_thumbnail: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube-5/design-94366.png?updatedAt=1688727498170"
            },
            {
                id: 165,
                skill_name: "Violin",
                skill_slug: "violin-course",
                skill_thumbnail: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube-5/design-94364.png?updatedAt=1688727498157"
            },
            {
                id: 187,
                skill_name: "FL Studio Begginer Music Production",
                skill_slug: "fl-studio-begginer-music-production-course",
                skill_thumbnail: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube-5/work-94371.png?updatedAt=1688727498065"
            },
            {
                id: 188,
                skill_name: "FL Studio 20 - Music Production In FL Studio for Mac & PC",
                skill_slug: "fl-studio-20--music-production-in-fl-studio-for-mac-pc-course",
                skill_thumbnail: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube-5/compass-94362.png?updatedAt=1688727498013"
            },
            {
                id: 189,
                skill_name: "FL Studio 12",
                skill_slug: "fl-studio-12-course",
                skill_thumbnail: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube-5/coding-94363.png?updatedAt=1688727497968"
            },
            {
                id: 190,
                skill_name: "FL Studio 12 Music Making",
                skill_slug: "fl-studio-12-music-making-course",
                skill_thumbnail: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube-5/design-94365.png?updatedAt=1688727497843"
            },
            {
                id: 191,
                skill_name: "FL Studio Hardstyle Production Hard Dance Music Production",
                skill_slug: "fl-studio-hardstyle-production-hard-dance-music-production-course",
                skill_thumbnail: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Learntube-5/box-94358.png?updatedAt=1688727494086"
            }
        ]
]

export const placementData = [
    {
      label: "Week 1",
      title: "Building the Ultimate Profile",
      subtitle: "CV + LinkedIn revamp to make your application stand out",
      video_link: "https://youtu.be/OKVe7qahDoc",
      thumbnail: "https://ik.imagekit.io/238r9emirjj/Optimized_folder/premium/placementImages/week-1.jpg?updatedAt=1688366093369",
    },
    {
      label: "Week 2",
      title: "Creating Your Job Hunt Plan",
      subtitle: "Learn winning strategies that will get you shortlisted",
      video_link: "https://youtu.be/93Y-_Pzk7SM",
      thumbnail: "https://ik.imagekit.io/238r9emirjj/Optimized_folder/premium/placementImages/week-2.jpg?updatedAt=1688366094136",
    },
    {
      label: "Week 3",
      title: "Mastering The Elevator Pitch",
      subtitle: "Land multiple job interviews with the right skills & attitude",
      video_link: "https://youtu.be/Oa73QfUg9WI",
      thumbnail: "https://ik.imagekit.io/238r9emirjj/Optimized_folder/premium/placementImages/week-3.jpg?updatedAt=1688366094579",
    },
    {
      label: "Week 4",
      title: "Live Interview Training",
      subtitle: "Get hired with mock interviews & personalised feedback",
      video_link: "https://youtu.be/AKUVJT7UYl0",
      thumbnail: "https://ik.imagekit.io/238r9emirjj/Optimized_folder/premium/placementImages/week-4.jpg?updatedAt=1688366094496",
    },
];

export const videoThumbnail = [
    'https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Group_8553.png?updatedAt=1686917725183',
    'https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Group_8549.png?updatedAt=1686917725222',
    'https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Group_8550.png?updatedAt=1686917725363',
    'https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Group_8547.png?updatedAt=1686917725406',
    'https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Group_8551.png?updatedAt=1686917725508',
    'https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Group_8552.png?updatedAt=1686917725668',
    'https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Group_8548.png?updatedAt=1686917725742',
    'https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Group_8546.png?updatedAt=1686917725828',
    'https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Group_8545.png?updatedAt=1686917725891',
    'https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Group_8533.png?updatedAt=1686917725959'
]

export const projects = [
    {
      id: 1,
      mentor: "Rahila Saleem",
      domain_icon: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/project-1/digital-marketing.png?updatedAt=1686737716822",
      domain: 'Digital Marketing',
      project_thumbnail:
        "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/thumbnails/digital-marketing-project-thumbnail.png?updatedAt=1686737808692",
      title: "Creating a Social Media Campaign for a Product Launch",
      description:
        "The objective of this project is to create and execute a comprehensive social media campaign to generate buzz, drive engagement, and increase sales for a new product launch.",
      skills_showcase: [
        "Social media strategy development",
        "Content creation and curation",
        "Social media advertising",
        "Analytics and reporting",
        "Project management",
      ],
      tools_showcase: [
        "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/project-1/Canva_-_png_0.png?updatedAt=1686750058981",
        "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/project-1/google_analytics.png?updatedAt=1686750063138",
        "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/project-1/Hootsuite_-_png_0.png?updatedAt=1686750064611",
        "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/project-1/instagram.png?updatedAt=1686750058966",
       
      ],
      companies_showcase: [
        "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/project-1/Gazoop_-_png_0.png?updatedAt=1686750059167",
        "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/project-1/ogilvy.png?updatedAt=1686750062352",
        "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/project-1/publicis_groupe.png?updatedAt=1686750059164",
        "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/project-1/Schbang_-_jpeg_0.png?updatedAt=1686750058978"
      ],
      download_link:
        "https://drive.google.com/file/d/1ap6selIs8LiW6tjPOd6poCyrJb5l031d/view?usp=drive_link",
    },
    {
      id: 2,
      mentor: "Dushyant Pathak",
      domain: 'Programming',
      domain_icon: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/project-2/programming.png?updatedAt=1686737693833",
      project_thumbnail:
        "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/thumbnails/programming-project-thumbnail.png?updatedAt=1686737808024",
      title:
        "Building an E-commerce Platform with Payment Integration and User Authentication",
      description:
        "The objective of this project is to develop a functional e-commerce platform that allows users to browse and purchase products and integrates secure payment processing.",
      skills_showcase: [
        "Web development (HTML, CSS, JavaScript)",
        "Backend development using NodeJs",
        "Database management (MySQL, PostgreSQL)",
        "Payment gateway integration (Stripe, PayPal)",
        "User authentication and authorization",
      ],
      tools_showcase: [
        "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/project-2/Node.js_-_png_0.png?updatedAt=1686750420514",
        "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/project-2/Oracle_MySQL_-_png_0.png?updatedAt=1686750420884",
        "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/project-2/Stripe_-_png_0.png?updatedAt=1686750420468",
        "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/project-2/Adobe_XD_Elements_-_png_0.png?updatedAt=1686750420189", 
      ],
      companies_showcase: [
        "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/project-2/Wipro_-_png_0.png?updatedAt=1686750486201",
        "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/project-2/IBM_-_jpeg_0__1_.png?updatedAt=1686750406617",
        "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/project-2/Stripe_-_png_1.png?updatedAt=1686750404814",
        "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/project-2/jp_morgan_chase.png?updatedAt=1686750398583"
      ],
      download_link:
        "https://drive.google.com/file/d/1ucbxVGTxi4kWkTthrbJ2WWPFI0V3PEI6/view?usp=drive_link",
    },
    {
      id: 3,
      mentor: "Anirban Paul",
      domain: 'Data Science',
      domain_icon: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/project-3/data-science.png?updatedAt=1686737743114",
      project_thumbnail:
        "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/thumbnails/data-science-project-thumbnail.png?updatedAt=1686737808493",
      title: "Building a Predictive Model for Stock Price Forecasting",
      description:
        "The objective of this project is to develop a predictive model that can forecast stock prices or market trends based on historical data. The model will help investors and traders make informed decisions by providing insights into potential price movements.",
      skills_showcase: [
        "Analysing and visualising trends and patterns",
        "Feature engineering using Python libraries",
        "Selecting and training time series models like ARIMA or LSTM",
        "Evaluating model performance and refining predictions with metrics and optimization techniques",
        "Python for data manipulation and analysis",
      ],
      tools_showcase: [
        "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/project-3/Python_Software_Foundation_-_png_0.png?updatedAt=1686750875757",
        "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/project-3/pandas.png?updatedAt=1686750875758",
        "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/project-3/matplotlib.png?updatedAt=1686750919583",
        "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/project-3/alpha_vantage__1_.png?updatedAt=1686750875760",
       
      ],
      companies_showcase: [
        "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/project-3/Amazon_-_png_0.png?updatedAt=1686750875778",
        "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/project-3/Deloitte_-_png_0.png?updatedAt=1686750875705",
        "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/project-3/cloudera.png?updatedAt=1686750875592",
        "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/project-3/Microsoft_-_png_0.png?updatedAt=1686750875711"
      ],
      download_link:
        "https://drive.google.com/file/d/1he-WBEONlpz0J_usyGDAOahfv4hXcSsQ/view?usp=drive_link",
    },
    {
      id: 4,
      mentor: "Dulab Deb",
      domain: 'Design',
      domain_icon: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/project-4/design.png?updatedAt=1686737764588",
      project_thumbnail:
        "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/thumbnails/design-project-thumbnail.png?updatedAt=1686737808572",
      title: "Designing an Engaging User Onboarding Experience",
      description:
        "The objective of this project is to create a user onboarding system which incorporates an easy payment system and also facilitates early product adoption through suggestive content strategy.",
      skills_showcase: [
        "User Experience Design Principles",
        "Interaction Design Techniques",
        "Content Strategy and Personalization",
        "Information Architecture Planning and Organization",
        "Usability Testing and User Research",
      ],
      tools_showcase: [
        "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/project-4/Figma_-_png_0.png?updatedAt=1686751222952",
        "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/project-4/UserTesting_-_png_0.png?updatedAt=1686751223507",
        "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/project-4/InVision_-_png_0.png?updatedAt=1686751223091",
        "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/project-4/Adobe_XD_Elements_-_png_6.png?updatedAt=1686751223447",
      
      ],
      companies_showcase: [
        "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/project-4/Tata_Consultancy_Services_-_png_0.png?updatedAt=1686751223490",
        "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/project-4/popxo.png?updatedAt=1686751223103",
        "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/project-4/Flipkart_-_png_1.png?updatedAt=1686751280597",
        "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/project-4/Disney__Hotstar_-_png_0__1_.png?updatedAt=1686751222924",
      ],
      download_link:
        "https://drive.google.com/file/d/1N-MNPtlPvSVn_fiC50KsvInUhYz_o8gv/view?usp=drive_link",
    },
    {
      id: 5,
      mentor: "Rishita Motwani",
      domain: 'Finance',
      domain_icon: "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/project-5/finance.png?updatedAt=1686737784270",
      project_thumbnail:
        "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/thumbnails/finance-project-thumbnail.png?updatedAt=1686737808180",
      title: "Financial Risk Management: Mitigating Risks for a Product Launch",
      description:
        "The objective of this project is to do a financial risk mitigation for a new car launch for a car manufacturer. This will help the business to take informed decisions and avoid losses due to unforeseen risks during project implementation.",
      skills_showcase: [
        "Risk Assessment and Analysis Techniques",
        "Project Planning and Scheduling",
        "Data Analysis for Risk Evaluation",
        "Decision-making under Uncertainty",
        "Project Risk Mitigation Strategies",
      ],
      tools_showcase: [
        "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/project-5/excel.png?updatedAt=1686751624475",
        "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/project-5/ms_powerpointt.png?updatedAt=1686751624127",
        "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/project-5/ms_projects.png?updatedAt=1686751624108",
        "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/project-5/tableau.png?updatedAt=1686751684136",
      
      ],
      companies_showcase: [
        "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/project-5/jp_morgan__1_.png?updatedAt=1686751622177",
        "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/project-5/KPMG_-_png_0.png?updatedAt=1686751621593",
        "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/project-5/EY_-_png_0.png?updatedAt=1686751684132",
        "https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/project-5/Deutsche_Bank_-_png_0__1_.png?updatedAt=1686751621587"
      ],
      download_link:
        "https://docs.google.com/spreadsheets/d/1N_fYO8P6YSLB31tfHYxaZ0WNrASgp7d0/edit?usp=share_link&ouid=111942812402022720730&rtpof=true&sd=true",
    },
];

export const features = [
    'Personalised Syllabus',
    'Verified Certifications',
    'Practical Projects',
    'Live Doubt Solving',
]

export async function downloadCertificate(link, setDownload) {
    const bytes = await fetch(link, {
        headers: {
            'Cache-Control': 'no-cache'
        }
    })
    .then((res) => res.arrayBuffer())
    .catch((err) => console.log(err))
  
    saveAs(new Blob([bytes]), `Certificate.jpg`)
    setDownload(true)
}

export const years = [
    {
        id: 0,
        year: '1 Year',
        image:'https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/1year.svg?updatedAt=1685861204276'
    },
    {
        id: 1,
        year: '3 Year',
        image:'https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/3year.svg?updatedAt=1685861189080'
    },
    {
        id: 2,
        year: '5 Year',
        image:'https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/5year.svg?updatedAt=1685861155995'
    }
]

export const companyLogo1 = [
    'https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Facebook_-_png_0__1_.png?updatedAt=1685624352896',
    'https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Amazon_-_jpeg_0.png?updatedAt=1685624249275',
    'https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Apple_-_png_0__1_.png?updatedAt=1685624249085',
    'https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Netflix_-_png_0__1_.png?updatedAt=1685624248825',
    'https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Google_-_png_0__1_.png?updatedAt=1685624248441'
]

export const companyLogo2 = [
    'https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/image_340.png?updatedAt=1685624248922',
    'https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/image_343.png?updatedAt=1685624509156',
    'https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/image_344.png?updatedAt=1685624248932',
    'https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/image_345.png?updatedAt=1685624248680',
    'https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Paytm_-_jpeg_0.png?updatedAt=1685624248692'
]

export const linkdInCertificateList = [
    {
        id: 1,
        name: 'Vidhi Mer',
        gender: '(She/Her)',
        designation: '.',
        post_link: "https://www.linkedin.com/feed/update/urn:li:activity:7113488469238161408/?actorCompanyId=13328177",
        profile_image: 'https://xgfy-czuw-092q.m2.xano.io/vault/OXY-0ZPV/BfAWyhk_qy6XMG9sOTj99vmnUmc/j4_kjg../1695273639728.jpg',
        certificate_image: "https://xgfy-czuw-092q.m2.xano.io/vault/OXY-0ZPV/XVLQRUJN-K7iFQf4E2FvjTHM3Cg/QyyLgg../1695987812617.jpg",
        description: "🚀📚 I'm thrilled to share my accomplishment and newfound knowledge in the world of digital marketing. 🌐💼 \n 📈 This journey has equipped me with essential skills to navigate the digital landscape and drive meaningful results. I'm excited to apply this knowledge to my career and beyond! 💪💡",
        likes: 60,
        icon_image: (
            <div className='w-[15px] h-full mr-1'>
                <img src="https://ik.imagekit.io/238r9emirjj/LinkdinUsers/emei2gdl9ikg7penkh9ij9llx.svg?updatedAt=1699081182178" width={14} height={14} className='w-full h-auto' alt="" />
            </div>
        ),
        comments: [
            {
                id: 1,
                user_name: "Shronit Ladhani",
                gender: '(He/Him)',
                user_image: "https://ik.imagekit.io/238r9emirjj/LinkdinUsers/1691577786209.jpg?updatedAt=1698758533634",
                designation: 'Entrepreneur | Angel Investor | 30 Under 30 | Triathlete | 2x TEDx Speaker | Hiring for Exciting Tech, Growth and Marketing Roles',
                description: "This is great. Best of luck <span style='color: #69B4FF;'>Vidhi Mer</span>"
            },
        ]
    },
    {
        id: 2,
        name: 'Sreeya S.',
        gender: '(She/Her)',
        designation: 'Analyst at EY | Student at BITS PILANI',
        post_link: "https://www.linkedin.com/feed/update/urn:li:activity:7086763519278276609/?actorCompanyId=13328177",
        profile_image: 'https://ik.imagekit.io/238r9emirjj/certifiedOptimisation/1693384172683.webp?updatedAt=1720514693519',
        certificate_image: "https://ik.imagekit.io/238r9emirjj/certifiedOptimisation/1689616086974.webp?updatedAt=1720514690381",
        description: "🔌📊 Exciting News! Just Completed the Introduction to Power BI Certification! 🚀🎉\nHey everyone,\nI hope this post finds you all in great spirits! 🌟 Today, I am thrilled to share a moment of joy in my professional development journey. I have just completed the 'Introduction to Power BI' certification. 💪🔥\nI highly recommend anyone who wants to harness the true potential of data to consider taking the Introduction to Power BI certification. Whether you are a data enthusiast, analyst, or business professional, this course equips you with the tools you need to drive impactful outcomes. 💡\nHere's to more growth, more learning, and a future filled with data-driven success! 🚀📊",
        likes: 35,
        icon_image: (
            <div className='w-[29px] flex h-full'>
                <img src="https://ik.imagekit.io/238r9emirjj/LinkdinUsers/emei2gdl9ikg7penkh9ij9llx.svg?updatedAt=1699081182178" width={15} height={15} className='w-[15px] h-auto' alt="" />
                <img src="https://ik.imagekit.io/238r9emirjj/LinkdinUsers/9wt27hvi2lgll1v30u00n0p5p.svg?updatedAt=1699081182096" width={15} height={15} className='w-[15px] h-auto -ml-1.5' alt="" />
            </div>
        ),
        comments: [
            {
                id: 1,
                user_name: "Tapas Saha",
                gender: '(He/Him)',
                user_image: "https://ik.imagekit.io/238r9emirjj/certifiedOptimisation/1652358524721.webp?updatedAt=1720514690527",
                designation: 'A result oriented techno-functional multi skilled IT professional with 28+ years of IT Experience across various manufacturing domain. Possessing expertise both in IT infrastructure setup and ERP implementation.',
                description: "Congratulations <span style='color: #69B4FF;'>Sreeya.</span>"
            },
            // {
            //     id: 2,
            //     user_name: "CareerNinja | LearnTube",
            //     designation: "16,718 followers",
            //     user_image: "https://ik.imagekit.io/238r9emirjj/LinkdinUsers/careerninja_logo.jpg?updatedAt=1698757218181",
            //     description: "Congratulations on your achievement <span style='color: #69B4FF;'>Sreeya S</span>. Keep upskilling and growing with LearnTube!"
            // }
        ]
    },
    {
        id: 3,
        name: 'Mustafa Maner',
        gender: '(He/Him)',
        post_link: "https://www.linkedin.com/feed/update/urn:li:activity:7082967078017675264/?actorCompanyId=13328177",
        designation: 'Passionate Electronics and Telecommunication Engineering Student | Intern at CodSoft Pvt Ltd.',
        profile_image: 'https://ik.imagekit.io/238r9emirjj/LinkdinUsers/1696431769803.jpg?updatedAt=1698758348979',
        certificate_image: "https://ik.imagekit.io/238r9emirjj/certifiedOptimisation/1688710945693.webp?updatedAt=1720514690483",
        description: "Thrilled to receive my certificate of completion!💫Grateful for the opportunity to enhance my skills and knowledge in the Computers and Programming Languages course offered by <span style='color: #69B4FF;'>CareerNinja | LearnTube</span> platform. Thank you, <span style='color: #69B4FF;'>Shronit Ladhani</span> Sir, for the incredible learning experience. Excited to apply my newfound expertise to drive success and make a positive impact.",
        likes: 31,
        icon_image: (
            <div className='w-[15px] h-full mr-1'>
                <img src="https://ik.imagekit.io/238r9emirjj/LinkdinUsers/emei2gdl9ikg7penkh9ij9llx.svg?updatedAt=1699081182178" width={14} height={14} className='w-full h-auto' alt="" />
            </div>
        ),
        comments: [
            {
                id: 1,
                user_name: "Shronit Ladhani",
                gender: '(He/Him)',
                user_image: "https://ik.imagekit.io/238r9emirjj/LinkdinUsers/1691577786209.jpg?updatedAt=1698758533634",
                designation: 'Entrepreneur | Angel Investor | 30 Under 30 | Triathlete | 2x TEDx Speaker | Hiring for Exciting Tech, Growth and Marketing Roles',
                description: "This is great. Best of luck <span style='color: #69B4FF;'>Mustafa Maner!</span>"
            },
            // {
            //     id: 2,
            //     user_name: "CareerNinja | LearnTube",
            //     designation: "16,718 followers",
            //     user_image: "https://ik.imagekit.io/238r9emirjj/LinkdinUsers/careerninja_logo.jpg?updatedAt=1698757218181",
            //     description: "Congratulations on completing the course <span style='color: #69B4FF;'>Mustafa Maner!</span>"
            // }
        ]
    },
    {
        id: 4,
        name: 'Muhammad P P',
        gender: '(He/Him)',
        post_link: 'https://www.linkedin.com/feed/update/urn:li:activity:7071003165982134272/?actorCompanyId=13328177',
        designation: 'Founder at Watermelon Graphics',
        profile_image: 'https://ik.imagekit.io/238r9emirjj/certifiedOptimisation/1697816280708.webp?updatedAt=1720514693099',
        certificate_image: "https://ik.imagekit.io/238r9emirjj/certifiedOptimisation/1685858526614.webp?updatedAt=1720514690440",
        description: "Excited to announce that I've successfully completed the UI-UX Fundamental for Beginners course from LearnTube by CareerNinja! This comprehensive course has equipped me with the essential skills and knowledge needed to kickstart my career in UI-UX design. I am now ready to create intuitive and user-centered experiences that will make a positive impact. Thank you, LearnTube and <span style='color: #69B4FF;'>CareerNinja | LearnTube</span> , for this valuable learning opportunity!",
        likes: 26,
        icon_image: (
            <div className='w-[29px] flex h-full'>
                <img src="https://ik.imagekit.io/238r9emirjj/LinkdinUsers/emei2gdl9ikg7penkh9ij9llx.svg?updatedAt=1699081182178" width={15} height={15} className='w-[15px] h-auto' alt="" />
                <img src="https://ik.imagekit.io/238r9emirjj/LinkdinUsers/3bhtnif60blspoiyhoex4lfx.svg?updatedAt=1699081881177" width={15} height={15} className='w-[15px] h-auto -ml-1.5' alt="" />
            </div>
        ),
        comments: [
            {
                id: 1,
                user_name: "PARAMASIVAN P",
                gender: '(He/Him)',
                user_image: "https://ik.imagekit.io/238r9emirjj/LinkdinUsers/1664770010213.jpg?updatedAt=1698758920528",
                designation: 'Director at Dhaanish Ahmed College Of Engineering, Chennai',
                description: "Excellent"
            },
            // {
            //     id: 2,
            //     user_name: "Mohammed Sinan",
            //     gender: '(He/Him)',
            //     user_image: "https://ik.imagekit.io/238r9emirjj/LinkdinUsers/1691714985499.jpg?updatedAt=1698759128956",
            //     designation: 'Mechanical Engineering Student',
            //     description: "Congrats 🎉"
            // },
        ]
    },
    {
        id: 5,
        name: 'Baibhab Pal',
        gender: '(He/Him)',
        designation: 'Product @Chefkart | Ex Founder',
        post_link: 'https://www.linkedin.com/feed/update/urn:li:activity:7105562765301342211/?actorCompanyId=13328177',
        profile_image: 'https://ik.imagekit.io/238r9emirjj/certifiedOptimisation/1635805601560.webp?updatedAt=1720514690487',
        certificate_image: "https://ik.imagekit.io/238r9emirjj/LinkdinUsers/1694098177347.jpg?updatedAt=1698759349284",
        description: "🚀 Excited to share that I recently completed a product management certificate course! By <span style='color: #69B4FF;'>CareerNinja | LearnTube</span>\n😎 Through this program, I gained core product management skills and knowledge that I'm eager to apply.\n🤔Let me know if you'd like to connect to discuss product management practices and methodologies. I'm happy to share my learnings from the course.\n<span style='color: #69B4FF;'>Shronit Ladhani</span>",
        likes: 25,
        icon_image: (
            <div className='w-[29px] flex h-full'>
                <img src="https://ik.imagekit.io/238r9emirjj/LinkdinUsers/emei2gdl9ikg7penkh9ij9llx.svg?updatedAt=1699081182178" width={15} height={15} className='w-[15px] h-auto' alt="" />
                <img src="https://ik.imagekit.io/238r9emirjj/LinkdinUsers/9wt27hvi2lgll1v30u00n0p5p.svg?updatedAt=1699081182096" width={15} height={15} className='w-[15px] h-auto -ml-1.5' alt="" />
            </div>
        ),
        comments: [
            // {
            //     id: 2,
            //     user_name: "CareerNinja | LearnTube",
            //     designation: "16,718 followers",
            //     user_image: "https://ik.imagekit.io/238r9emirjj/LinkdinUsers/careerninja_logo.jpg?updatedAt=1698757218181",
            //     description: "Congratulations on your first milestone <span style='color: #69B4FF;'>Baibhab Pal!</span>"
            // }
        ]
    },
    {
        id: 6,
        name: 'Gajjar Mona',
        gender: '(She/Her)',
        designation: 'Student at New L.J ENG & TECH.',
        post_link: 'https://www.linkedin.com/feed/update/urn:li:activity:7091734162818146304/?actorCompanyId=13328177',
        profile_image: 'https://ik.imagekit.io/238r9emirjj/LinkdinUsers/1690876595417.webp?updatedAt=1725949537463',
        certificate_image: "https://ik.imagekit.io/238r9emirjj/LinkdinUsers/1690801179659.jpg?updatedAt=1698759757119",
        description: "I had successfully completed an extensive training program in AngularJS. Throughout the course, I demonstrated exceptional understanding and implementation of AngularJS concepts, including data binding, directives, controllers, and routing. I commitment to learning and enthusiasm for front-end development were evident in I outstanding performance and the successful completion of various hands-on projects. With this certification, I showcases a valuable skill set and is well-prepared to contribute to the development of dynamic and responsive web applications using AngularJS.\nThankyou <span style='color: #69B4FF;'>CareerNinja | LearnTube</span> to help me throughout learning",
        icon_image: (
            <div className='w-[15px] h-full mr-1'>
                <img src="https://ik.imagekit.io/238r9emirjj/LinkdinUsers/emei2gdl9ikg7penkh9ij9llx.svg?updatedAt=1699081182178" width={14} height={14} className='w-full h-auto' alt="" />
            </div>
        ),
        likes: 24,
        comments: [
            // {
            //     id: 2,
            //     user_name: "CareerNinja | LearnTube",
            //     designation: "16,718 followers",
            //     user_image: "https://ik.imagekit.io/238r9emirjj/LinkdinUsers/careerninja_logo.jpg?updatedAt=1698757218181",
            //     description: "Congratulations on your achievement <span style='color: #69B4FF;'>Gajjar Mona.</span> We hope you keep upskilling with LearnTube to take your career to new heights 🚀!"
            // }
        ]
    },
    {
        id: 7,
        name: 'Poonam Gambhire',
        gender: '(She/Her)',
        designation: 'Matoshri college of engineering and research centre Nashik',
        post_link: 'https://www.linkedin.com/feed/update/urn:li:activity:7092112961221124096/?actorCompanyId=13328177',
        profile_image: 'https://ik.imagekit.io/238r9emirjj/LinkdinUsers/1691414895232.jpg?updatedAt=1698759945239',
        certificate_image: "https://ik.imagekit.io/238r9emirjj/LinkdinUsers/1690891494140.jpg?updatedAt=1698760021218",
        description: "I'm happy to share that I've obtained a new certification : JAVA Introduction from <span style='color: #69B4FF;'>CareerNinja | LearnTube</span>",
        likes: 24,
        icon_image: (
            <div className='w-[15px] h-full mr-1'>
                <img src="https://ik.imagekit.io/238r9emirjj/LinkdinUsers/emei2gdl9ikg7penkh9ij9llx.svg?updatedAt=1699081182178" width={14} height={14} className='w-full h-auto' alt="" />
            </div>
        ),
        comments: [
            {
                id: 1,
                user_name: "Divya Borse",
                gender: '(She/Her)',
                user_image: "https://ik.imagekit.io/238r9emirjj/LinkdinUsers/1690431999406.jpg?updatedAt=1698760337072",
                designation: 'Attended Savitribai Phule Pune University',
                description: "Congratulations 🎊"
            },
            {
                id: 2,
                user_name: "Reshma Gambhire",
                gender: '(She/Her)',
                user_image: "https://ik.imagekit.io/238r9emirjj/certifiedOptimisation/1691414582096.webp?updatedAt=1720514690442",
                designation: 'Student at Savitribai Phule Pune University',
                description: "Nice 👍"
            }
        ]
    },
    {
        id: 8,
        name: 'Akshay Ravella',
        gender: '(He/Him)',
        designation: 'Data Science & Analytics Enthusiast || Python | SQL | Advanced Excel | Power BI | Tableau | ML',
        post_link: 'https://www.linkedin.com/feed/update/urn:li:activity:7069601251298086912/?actorCompanyId=13328177',
        profile_image: 'https://ik.imagekit.io/238r9emirjj/certifiedOptimisation/1689604333123.webp?updatedAt=1720514690489',
        certificate_image: "https://ik.imagekit.io/238r9emirjj/LinkdinUsers/image%20(1).webp?updatedAt=1725949745864",
        description: "🎉 Excited to share my latest achievement! 🎓🚀\nI'm thrilled to announce that I have successfully earned my Tableau Advanced certificate! 📊✨\nDuring this certification journey, I honed my skills in advanced data visualization, storytelling, and interactive dashboards using Tableau. I mastered the art of creating dynamic and visually appealing visualizations that effectively convey complex information in a concise and intuitive manner.\nI am grateful for the opportunity to have expanded my analytical toolkit and enhanced my data visualization skills through this certification.",
        likes: 24,
        icon_image: (
            <div className='w-[15px] h-full mr-1'>
                <img src="https://ik.imagekit.io/238r9emirjj/LinkdinUsers/emei2gdl9ikg7penkh9ij9llx.svg?updatedAt=1699081182178" width={14} height={14} className='w-full h-auto' alt="" />
            </div>
        ),
        comments: [
            // {
            //     id: 2,
            //     user_name: "CareerNinja | LearnTube",
            //     designation: "16,718 followers",
            //     user_image: "https://ik.imagekit.io/238r9emirjj/LinkdinUsers/careerninja_logo.jpg?updatedAt=1698757218181",
            //     description: "Congratulations on your achievement <span style='color: #69B4FF;'>Akshay Ravella</span>. We hope you keep upskilling with LearnTube to kickstart your career!"
            // }
        ]
    },
    {
        id: 9,
        name: 'Rohit Nikumbh',
        gender: '(He/Him)',
        designation: 'Mechanical Engineer | CAD Designer | Formula Student Champion | Digital Marketing',
        post_link: 'https://www.linkedin.com/feed/update/urn:li:activity:7111336933695352832/?actorCompanyId=13328177',
        profile_image: 'https://ik.imagekit.io/238r9emirjj/certifiedOptimisation/1696962035002.webp?updatedAt=1720514693561',
        certificate_image: "https://ik.imagekit.io/238r9emirjj/LinkdinUsers/1695474845305.jpg?updatedAt=1698761377324",
        description: "🚀 Exciting News! I've just completed a comprehensive digital marketing course with certification from <span style='color: #69B4FF;'>CareerNinja | LearnTube</span>, in association with Praven Rao, the marketing director of SAP .\n🎓This course has been an incredible journey, allowing me to delve deep into the world of digital marketing. I've gained expertise in various strategies, from SEO to social media marketing, and I'm thrilled to bring this knowledge to the table.\n💼 With digital marketing being at the forefront of modern business, I'm excited to apply these skills and insights to drive results and create impact. Whether you're looking to boost your online presence, engage with your target audience, or elevate your brand's digital strategy, I'm here to help!\n🙏 Huge thanks to Praven Rao for the invaluable guidance and insights throughout this course. Your expertise has been a game-changer.\n📚 Lifelong learning is a core value of mine, and I can't wait to continue expanding my knowledge in the dynamic world of digital marketing. Let's connect and explore how we can collaborate or share ideas in this exciting field.",
        likes: 17,
        icon_image: (
            <div className='w-[15px] h-full mr-1'>
                <img src="https://ik.imagekit.io/238r9emirjj/LinkdinUsers/emei2gdl9ikg7penkh9ij9llx.svg?updatedAt=1699081182178" width={14} height={14} className='w-full h-auto' alt="" />
            </div>
        ),
        comments: [
            {
                id: 1,
                user_name: "Devendra Wagh",
                gender: '(he/Him)',
                user_image: "https://ik.imagekit.io/238r9emirjj/certifiedOptimisation/1610471216036.webp?updatedAt=1720514690437",
                designation: 'Sr. Manager Sales and Marketing',
                description: "Congratulations <span style='color: #69B4FF;'>Rohit</span></p>"
            },
            // {
            //     id: 2,
            //     user_name: "CareerNinja | LearnTube",
            //     designation: "16,718 followers",
            //     user_image: "https://ik.imagekit.io/238r9emirjj/LinkdinUsers/careerninja_logo.jpg?updatedAt=1698757218181",
            //     description: "Congratulations on completing the course <span style='color: #69B4FF;'>Rohit Nikumbh!<span>"
            // }
        ]
    },
    {
        id: 10,
        name: 'Sudharshanam R',
        gender: '(He/Him)',
        designation: '--',
        post_link: 'https://www.linkedin.com/feed/update/urn:li:activity:7100824941461176320/?actorCompanyId=13328177',
        profile_image: 'https://ik.imagekit.io/238r9emirjj/certifiedOptimisation/1695773173560.webp?updatedAt=1720514694109',
        certificate_image: "https://ik.imagekit.io/238r9emirjj/certifiedOptimisation/1692968590977.webp?updatedAt=1720514690388",
        description: "Thrilled 🚀 to announce that I've successfully completed a course on Visual Design Principles in UI-UX Design.Learned the significance of color theory, typography, and spacing in crafting compelling interfaces.\n⚡Explored the balance between aesthetics and usability, ensuring every design decision enhances the user experience.\n🌟 Studied the principles of grid systems, alignment, and consistency, creating seamless and intuitive layouts.\n🔁 Embraced the iterative design process, understanding the value of feedback and refinement for a very good design💫.",
        likes: 16,
        icon_image: (
            <div className='w-[15px] h-full mr-1'>
                <img src="https://ik.imagekit.io/238r9emirjj/LinkdinUsers/emei2gdl9ikg7penkh9ij9llx.svg?updatedAt=1699081182178" width={14} height={14} className='w-full h-auto' alt="" />
            </div>
        ),
        comments: [
            // {
            //     id: 1,
            //     user_name: "YUVAN N K",
            //     gender: '(he/Him)',
            //     user_image: "https://ik.imagekit.io/238r9emirjj/LinkdinUsers/1666604973548.jpg?updatedAt=1698762315710",
            //     designation: 'Student at RMK ENGINEERING COLLEGE, CHENNAI',
            //     description: "Great job <span style='color: #69B4FF;'>SUDHARSHANAM R</span>"
            // },
            {
                id: 2,
                user_name: "Arsath haja Mohideen",
                gender: '(he/Him)',
                user_image: "https://ik.imagekit.io/238r9emirjj/certifiedOptimisation/1690823675248.webp?updatedAt=1720514690410",
                designation: 'Student at R.M.K Engineering College',
                description: "Congratulations 👏 <span style='color: #69B4FF;'>SUDHARSHANAM R</span>"
            },
        ]
    },
]

export const timeFormat = (time) => {
    let minutes = Math.floor(time / 60)
    let seconds = time % 60

    return minutes === 0 ? `${seconds}s` : `${minutes}m ${seconds}s`
}

export const packUsers = [
    {
        name: 'Kevin Thomas',
        pack: 'Beginner Pack',
        image: 'https://ik.imagekit.io/238r9emirjj/certified%20app/man2.jpg?updatedAt=1713178941231',
        description: 'LearnTube boosted my confidence & I gave job interviews that really helped me secure a Career in Data Analysis.'
    },
    {
        name: 'Amrita',
        pack: 'Beginner Pack',
        image: 'https://ik.imagekit.io/238r9emirjj/certified%20app/woman2.jpg?updatedAt=1713178941830',
        description: 'I was looking for Certificates to boost my chances of landing a job and Learntubeʼs beginner pack became my ultimate savior.'
    },
    {
        name: 'Shraddha Rao',
        pack: 'Intermediate Pack',
        image: 'https://ik.imagekit.io/238r9emirjj/certified%20app/woman3.jpg?updatedAt=1713178941485',
        description: 'This pack gave me Basic to Advanced skills and boosted my career opportunities.'
    },
    {
        name: 'Rhea Sharma',
        pack: 'Intermediate Pack',
        image: 'https://ik.imagekit.io/238r9emirjj/certified%20app/woman1.jpg?updatedAt=1713178941627',
        description: 'LearnTube Helped me improving my practical skills I was struggling with in my first job.'
    },
    {
        name: 'Zain Shaikh',
        pack: 'Intermediate Pack',
        image: 'https://ik.imagekit.io/238r9emirjj/certified%20app/woman5.jpg?updatedAt=1713178940631',
        description: 'LearnTube helped with restart my career with practical skills after my gap.'
    },
    {
        name: 'Jennifer',
        pack: 'Intermediate Pack',
        image: 'https://ik.imagekit.io/238r9emirjj/certified%20app/woman4.jpg?updatedAt=1713178941953',
        description: 'The Intermediate Pack provided me with practical skills and boosted my confidence in my job applications.'
    },
    {
        name: 'Sameer Singh',
        pack: 'Advanced Pack',
        image: 'https://ik.imagekit.io/238r9emirjj/certified%20app/man5.jpg?updatedAt=1713178942754',
        description: 'With help of LearnTubeʼs Advanced Pack, I was able to secure multiple job offers at same time.'
    },
    {
        name: 'Dhruv Rathi',
        pack: 'Advanced Pack',
        image: 'https://ik.imagekit.io/238r9emirjj/certified%20app/man1.jpg?updatedAt=1713178942751',
        description: 'With the placement support, I was able to recognize my faults and improve them which led me to secure my first Job.'
    },
    {
        name: 'Shahmeer',
        pack: 'Advanced Pack',
        image: 'https://ik.imagekit.io/238r9emirjj/certified%20app/man4.jpg?updatedAt=1713178942484',
        description: 'Within a short period, I not only secured multiple job offers but also negotiated a higher salary with help of LearnTubeʼs Advanced Pack.'
    },
    {
        name: 'Siya Bose',
        pack: 'Advanced Pack',
        image: 'https://ik.imagekit.io/238r9emirjj/certified%20app/man3.jpg?updatedAt=1713178942481',
        description: 'This acted as an all rounder course for me & helped me secure my first internship at Microsoft.'
    },
]

export default constants