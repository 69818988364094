import React, { Suspense, lazy } from 'react';
import {  Routes, Route} from 'react-router-dom';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'react-circular-progressbar/dist/styles.css'
import Layout from './pages/Layout';
import Loader from './components/Loader';
import ThankYou from './pages/AnalysisPage/ThankYou';
import LandingPage from './pages/LandingPage'
import PageNotFound from './components/PageNotFound';
import * as Sentry from "@sentry/react";
import FallbackUI from './components/FallbackUI';

const CourseAndSkillSelectionPage = lazy(()=> import('./pages/SkillSelectionPage'))
const OnboardingPage = lazy(() => import(/* webpackPrefetch: true */ './pages/OnboardingPage'))
const Register = lazy(() => import(/* webpackPrefetch: true */ './pages/Register'))
const AnalysisPage = lazy(() => import(/* webpackPrefetch: true */ './pages/AnalysisPage'))
const PostCertifcatePurchase = lazy(() => import('./pages/PostCertifcatePurchase'))
const PaidPostPaymentFeature = lazy(() => import('./pages/PaidPostPaymentFeature'))
const PostPaymentThankYou = lazy(() => import('./components/PostPaymentThankYou'))
const SkillPage = lazy(() => import('./pages/SkillPage'))
const MCQPage = lazy(() => import('./pages/MCQPage'))
const CertifiedPricing = lazy(() => import('./pages/CertifiedPricingPage'))
const MultiskillSelection2 = lazy(() => import('./pages/MultiskillSelectionVersion2'))
const PricingPageVersion2 = lazy(() => import('./pages/PricingPage/PricingPageVersion2'))
const SkillLevelPage = lazy(() => import('./pages/PersonaliseSkillPage'))
const PersonaliseAnalysis = lazy(() => import('./pages/PersonaliseAnalysisPage'))
const PersonaliseQuestionarySection = lazy(() => import('./pages/PersonaliseQuestionsPage'))
const PersonalisedCourse = lazy(() => import('./pages/PersonaliseCoursePage'))
const CertificateCourse = lazy(() => import('./pages/CertifiedCoursePage'))
const CourseLoader = lazy(() => import('./components/CourseLoader'))
const PersonalisedPricing = lazy(() => import('./pages/PersonalisedPricingPage'))

function App() {

    return (
        <Sentry.ErrorBoundary fallback={({ resetError }) => <FallbackUI resetError={resetError}/>}>
            <Routes>
                <Route path='/' element={<Layout/>}>
                    <Route path=':skill_name?/:utm_source?' element={<Suspense fallback={<Loader/>}><LandingPage/></Suspense>}/>
                    <Route path='1/course-page' element={<Suspense fallback={<Loader/>}><CourseAndSkillSelectionPage/></Suspense>}/>
                    <Route path='2/skill-page' element={<Suspense fallback={<Loader/>}><SkillPage/></Suspense>}/>
                    <Route path='onboarding' element={<Suspense fallback={<Loader/>}><OnboardingPage/></Suspense>}/>
                    <Route path='mcq/:id' element={<Suspense fallback={<Loader/>}><MCQPage/></Suspense>}/>
                    <Route path='register' element={<Suspense fallback={<Loader/>}><Register/></Suspense>}/>
                    <Route path='analysis'>
                        <Route path=':token?' element={<Suspense fallback={<Loader/>}><AnalysisPage/></Suspense>}/>
                        <Route path='leaderboard'>
                            <Route path='v2' element={<Suspense fallback={<Loader/>}><PostCertifcatePurchase/></Suspense>}/>
                            <Route path='paid' element={<Suspense fallback={<Loader/>}><PaidPostPaymentFeature/></Suspense>}/>
                        </Route>
                        <Route path='skillcertificates/v2' element={<Suspense fallback={<Loader/>}><MultiskillSelection2/></Suspense>}/>
                        <Route path='thank-you' element={<Suspense fallback={<Loader/>}><PostPaymentThankYou/></Suspense>}/>
                    </Route>
                    <Route path='personalised-course'>
                        <Route index element={<Suspense fallback={<Loader/>}><SkillLevelPage/></Suspense>} />
                        <Route path='questions/:id' element={<Suspense fallback={<Loader/>}><PersonaliseQuestionarySection/></Suspense>} />
                        <Route path='loader' element={<Suspense fallback={<Loader/>}><CourseLoader/></Suspense>} />
                        <Route path='certificate-course' element={<Suspense fallback={<Loader/>}><CertificateCourse/></Suspense>} />
                        <Route path='analysis' element={<Suspense fallback={<Loader/>}><PersonaliseAnalysis/></Suspense>} />
                        <Route path='pricing' element={<Suspense fallback={<Loader/>}><CertifiedPricing/></Suspense>} />
                        <Route path='personalised-pricing' element={<Suspense fallback={<Loader/>}><PersonalisedPricing/></Suspense>} />
                        <Route path='course' element={<Suspense fallback={<Loader/>}><PersonalisedCourse/></Suspense>} />
                    </Route>
                    <Route path='pricing'>
                        <Route index element={<Suspense fallback={<Loader/>}><PricingPageVersion2/></Suspense>}/>
                        <Route path='thank-you' element={<Suspense fallback={<Loader/>}><ThankYou/></Suspense>}/>
                    </Route>
                    <Route path='/error' element={<PageNotFound/>}/>
                    <Route path='*' element={<PageNotFound/>}/>
                </Route>
            </Routes>
        </Sentry.ErrorBoundary>
  );
}

export default App;
