import React, { useEffect, useMemo, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchCourseSyllabus, selectListModalStatus, selectLoadingStatus, selectSuggestedCourseDetail, selectSuggestedCourseList } from '../../redux/postPaymentSlice'
import { Dialog } from '@headlessui/react'
import { useAddonContext } from '../../context/AddonFeatureContext'
import { toast } from 'react-toastify'
import { ImageComponent } from '../NewPostPaymentFeature/CertificateDownload'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark } from '@fortawesome/free-solid-svg-icons'
import CocreatedBy from '../NewPostPaymentFeature/CocreatedBy'
import NewSyllabusDetails from './NewSyllabusDetails'
import NewProjectsAndOutcomes from './NewProjectsAndOutcomes'


export const BottomPriceSection = ({ type }) => {

    const { addToCart, personalisedSelected, setPersonalisedSelected } = useAddonContext()

    const handleCart = () => {
        if (type === 'personalize'){
            if (personalisedSelected){
                toast.success("Already In Cart!")
            }else {
                toast.success("Added to Cart")
                setPersonalisedSelected(true)
            }
        }else {
            addToCart('Personalised Course & Certificate For 5 Subjects', 999, 1799)
        }
    }

    return <div className='sticky bg-[#2D4F71] z-50 left-0 bottom-0 flex justify-between items-center py-3'>
        <h2 className='text-xl font-medium'>₹{type === 'personalize' ? 199 : 999} <span className='text-danger text-[15px] font-medium'>₹</span><span className='text-danger text-[15px] font-medium line-through'>{type === 'personalize' ? 599 : 1799}</span></h2>
        <div className='bg-[#7FC241] py-2 px-6 rounded-lg font-semibold text-black cursor-pointer' onClick={handleCart}>
            Add To Cart
        </div>
    </div>
}

export const filledstars = (randomNo) => Array.from({ length: randomNo }, (_, index) => (
    <ImageComponent key={index} source={'https://ik.imagekit.io/238r9emirjj/Post%20Payment/Star.png?updatedAt=1704375792364'} />
))

export const notFilledstars = (randomNo) => Array.from({ length: randomNo === 4.5 ? 1 : 0 }, (_, index) => (
    <ImageComponent key={index} source={'https://ik.imagekit.io/238r9emirjj/Post%20Payment/Star%20(2).png?updatedAt=1704520939066'} />
))


const NewCourseDetailsModal = ({ type }) => {

    const courseDetail = useSelector(selectSuggestedCourseDetail)
    const loading = useSelector(selectLoadingStatus)
    const listModalStatus = useSelector(selectListModalStatus)
    const suggestedCourseListBundle = useSelector(selectSuggestedCourseList)

    const outcome = courseDetail?.outcome
    const projects = courseDetail?.projects
    const suggestedCourseList = suggestedCourseListBundle?.suggested_data

    const dispatch = useDispatch()
    const lessonRef = useRef(null)
    const projectRef = useRef(null)

    const { courseName, rating, student, setShowCourseModal, showCourseModal, setShowPersonaliseAddon, showPlacement, userGoal: jobRole, setShowPurchasedListModal, courseIndex } = useAddonContext()

    const scrollToLesson = (ref) => {
        if (ref.current){
            ref.current.scrollIntoView({ behavior: 'smooth' })
        }
    }
    
    const lesson = useMemo(() => {
        return courseDetail?.syllabus?.reduce((count, item) => item?.lesson_list?.length + count, 0)
    }, [courseDetail])

    useEffect(() => {
        const courseExist = suggestedCourseList?.some((course) => course?.id === courseIndex)
        console.log(courseIndex)
        if (courseExist){
            if (jobRole && type !== 'personalize'){
                dispatch(fetchCourseSyllabus({ type: 'bundle', certified_bundle_course_id: courseIndex }))
            }
        }
    }, [courseIndex, dispatch, jobRole, suggestedCourseList, type])


    return (
        <div>
            <Dialog
                open={showCourseModal}
                onClose={() => setShowCourseModal(false)}
                as='div'
                className="absolute inset-0 flex flex-col mx-auto text-white"
            >
                <Dialog.Overlay className="fixed inset-0 mx-auto z-10 bg-black opacity-50" />
                <Dialog.Title/>
                <Dialog.Description/>
                <div className={`bg-secondary_background max-w-2xl rounded-2xl w-[90%] mx-auto my-auto z-[80] relative max-h-[650px] md:max-h-[550px] overflow-y-scroll`}>
                    <div className='p-3'>
                        <div className='absolute right-3 top-3 cursor-pointer' onClick={() => {
                            if(listModalStatus){
                                setShowPurchasedListModal(true)
                            }
                            if (showPlacement){
                                setShowPersonaliseAddon(true)
                            }
                            setShowCourseModal(false)
                        }}>
                            <FontAwesomeIcon icon={faXmark} className='text-2xl'/>
                        </div>
                        <div className='pb-3'>
                            <h3 className='text-xl font-medium leading-6 pr-8'>{courseName}</h3>
                            <div className='flex gap-2 items-center mt-1'>
                                <div className='flex'>
                                    {filledstars(rating)}
                                    {notFilledstars(rating)}
                                </div>
                                <p className='text-xs text-white/50 mt-0.5'>{student} Students</p>
                            </div>
                        </div>
                        <div className='overflow-y-scroll'>
                            <div className='bg-[#213A51] mt-5 pt-3 pb-2 px-6 rounded-2xl text-xs text-white/50'>
                                {loading ? <div className='flex w-full justify-between gap-3 items-center pb-3 border-b-[1.5px] h-[58px] border-[#335373]'>
                                    <div className='flex-1 bg-[#335373] rounded-lg h-full animate-pulse'>

                                    </div>
                                    <div className='flex-1 bg-[#335373] rounded-lg h-full animate-pulse'>

                                    </div>
                                    <div className='flex-1 bg-[#335373] rounded-lg h-full animate-pulse'>

                                    </div>
                                    <div className='flex-1 bg-[#335373] rounded-lg h-full animate-pulse'>

                                    </div>
                                </div> : <div className='flex flex-wrap justify-between gap-3 text-center pb-3 border-b-[1.5px] border-[#335373]'>
                                    <div className='flex underline underline-offset-1 items-center cursor-pointer gap-2' onClick={() => scrollToLesson(lessonRef)}>
                                        <img src="https://ik.imagekit.io/238r9emirjj/certified%20app/Component%2078.png?updatedAt=1712660319333" width={12} height={16} alt="" />
                                        <p className='leading-3'>{lesson} Lessons</p>
                                    </div>
                                    <div className='flex items-center gap-2'>
                                        <img src="https://ik.imagekit.io/238r9emirjj/certified%20app/Vector%20(1).png?updatedAt=1712660319197" width={16} height={16} alt="" />
                                        <p className=''>{`${Math.floor((lesson * 15)/60)}hr ${(lesson*15)%60 === 0 ? '': (lesson*15)%60+'m'}`}</p>
                                    </div>
                                    <div className='flex items-center gap-2 underline underline-offset-1 cursor-pointer' onClick={() => scrollToLesson(projectRef)}>
                                        <img src="https://ik.imagekit.io/238r9emirjj/certified%20app/Component%2078.png?updatedAt=1712660319333" width={14} height={16} alt="" />
                                        <p className='leading-3'>{projects?.slice(0, 5)?.length} Projects</p>
                                    </div>
                                    <div className='flex gap-2 items-center'>
                                        <img src="https://ik.imagekit.io/238r9emirjj/certified%20app/Component%2077.png?updatedAt=1712660855640" width={14} height={12} alt="" />
                                        <p className=''>Certificate Of Completion</p>
                                        
                                    </div>
                                </div>}
                                <CocreatedBy/>
                            </div>
                            <div className='mt-5'>
                                <h3 className='text-lg font-semibold mb-1'>Outcomes</h3>
                                <div className=''>
                                    {loading ? <div className=''>
                                        {Array.from({ length: 5}, (_, index) => (
                                            <div key={index} className={`bg-[#14395F] border-b px-2 py-6 animate-pulse flex items-center gap-2`}>
                                            
                                            </div>
                                        ))}
                                    </div>
                                    : <NewProjectsAndOutcomes
                                        list={outcome}
                                    />}
                                </div>
                            </div>
                            <div className='mt-4'>
                                <h3 ref={lessonRef} className='text-lg font-semibold mb-1'>Syllabus</h3>
                                <NewSyllabusDetails
                                    loading={loading}
                                />
                            </div>
                            <div className='mt-4'>
                                <h3 className='text-lg font-semibold mb-1'>Projects</h3>
                                <div ref={projectRef} className=''>
                                    {loading ? <div className=''>
                                        {Array.from({ length: 5}, (_, index) => (
                                            <div key={index} className={`bg-[#14395F] ${index === 0 ? 'rounded-t-xl' : index === 4 ? 'rounded-b-xl' : ''} border-b px-2 py-6 animate-pulse flex items-center gap-2`}>
                                            
                                            </div>
                                        ))}
                                    </div> :
                                    <NewProjectsAndOutcomes
                                        list={projects}
                                    />}
                                </div>
                            </div>
                            {type !== 'user-made' && type !== 'post-payment' && <BottomPriceSection
                                type={type}
                            />}
                        </div>
                    </div>
                </div>
            </Dialog>
        </div>
    )
}

export default NewCourseDetailsModal